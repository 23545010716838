define('fitbase/auth/login/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Route.extend(_unauthenticatedRouteMixin.default, {
    model: function model() {
      return {
        email: '',
        password: '',
        error: ''
      };
    },


    actions: {
      doLogin: function doLogin() {
        var _this = this;

        var _get = get(this, 'currentModel'),
            email = _get.email,
            password = _get.password;

        set(this.currentModel, 'error', '');
        get(this, 'session').authenticate('authenticator:application', email, password).then(function () {}).catch(function () {
          set(_this.currentModel, 'error', 'Login failed. Try again');
        });
      },
      clearError: function clearError() {
        set(this.currentModel, 'error', null);
      }
    }
  });
});