define('fitbase/components/checkin-replies/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var Component = Ember.Component;
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  var get = Ember.get;
  var isEmpty = Ember.isEmpty;
  exports.default = Component.extend({
    init: function init() {
      this.sortReplies = ["sentAt:desc", "insertedAt:desc"];
      this._super.apply(this, arguments);
    },

    clientEmail: alias('client.email'),
    classNames: ["card"],
    repliesSorted: computed('replies.[]', 'client.{hasUncoachedMessage,lastMessageAt,lastCoachedAt}', function () {
      var list = this.get('replies').filterBy('sentAt');
      var hasNew = this.get('client.hasUncoachedMessage');

      if (hasNew) {
        var lastCoachedAt = EmberObject.create({
          isLastCoachedAt: true,
          sentAt: this.get('client.lastCoachedAt')
        });
        list.pushObject(lastCoachedAt);
      }

      return list.sortBy('sentAt', 'insertedAt').reverse();
    }),

    draft: alias('checkin.checkinReplyDraft'),

    draftIsSendable: computed('draft.{id,body}', function () {
      return !isEmpty(this.draftBody) && !isEmpty(get(this, 'draft.id'));
    }),

    draftIsSaving: computed('draft.{hasDirtyAttributes,isSaving}', function () {
      return get(this, 'draft.isSaving') && get(this, 'draft.hasDirtyAttributes');
    }),

    draftBody: computed('draft.body', function () {
      return get(this, 'draft.body') || '';
    }),
    actions: {
      sendReply: function sendReply() {
        get(this, 'sendReply').apply(undefined, arguments);
      },
      updateCoachedAt: function updateCoachedAt() {
        get(this, 'updateCoachedAt').apply(undefined, arguments);
      }
    }
  });
});