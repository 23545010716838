define('fitbase/coach/clients/client/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Controller.extend({
    noteExpanded: false,
    expandedClass: computed('noteExpanded', function () {
      if (!get(this, 'noteExpanded')) {
        return 'is-collapsed';
      } else {
        return 'is-collapsible';
      }
    }),
    actions: {
      saveNote: function saveNote() {
        set(this, 'noteExpanded', false);
        get(this, 'model').save();
      },
      expandNote: function expandNote() {
        set(this, 'noteExpanded', true);
      }
    },
    deactivate: function deactivate() {
      set(this, 'noteExpanded', false);
    }
  });
});