define('fitbase/checkin-field/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    text: attr('string'),
    unit: attr('string'),
    sequence: attr('number'),
    archivedAt: attr('date'),
    _imported: false,

    // relationships
    checkinForm: belongsTo('checkin-form'),
    checkinValue: belongsTo('checkin-value')
  });
});