define('fitbase/coach/clients/client/edit/checkin-form/create-template/route', ['exports', 'fitbase/mixins/uses-modals'], function (exports, _usesModals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_usesModals.default, {
    isModal: true,
    noResetScroll: true,

    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      var template = this.get('currentModel');
      if (template.get('isNew')) {
        template.deleteRecord();
      }
      this.controller.set('success', false);
    },
    model: function model(params, transition) {
      var _this = this;

      var checkinForm = this.modelFor('coach.clients.client.edit.checkin-form');
      return checkinForm.get('checkinFields').then(function (fields) {
        if (fields.length) {
          var template = _this.store.createRecord('checkin-template', { name: "" });
          template.setFields(fields);
          return template;
        } else {
          transition.abort();
        }
      });
    },


    actions: {
      cancel: function cancel() {
        this.send('closeModal');
      },
      closeModal: function closeModal() {
        this.controller.set('success', false);
        this.replaceWith('coach.clients.client.edit.checkin-form');
      },
      save: function save() {
        var _this2 = this;

        var template = this.get('currentModel');
        template.save().then(function () {
          _this2.send('success');
        });
      },
      success: function success() {
        this.controller.set('success', true);
      }
    }
  });
});