define('fitbase/components/header-nav/trainer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var get = Ember.get;
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  exports.default = Component.extend({
    tagName: 'header',
    classNames: ['header'],
    classNameBindings: ['isLight:light'],
    currentUser: alias('session.currentUser'),
    email: alias('session.currentUser.email'),
    isLight: true,
    session: service(),
    intercom: service(),

    doLogout: function doLogout() {},
    didInsertElement: function didInsertElement() {
      this.get("intercom").stop();
    },
    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);
      var intercom = this.get('intercom');
      this.get('session.currentUser').then(function (user) {
        var name = user.get('name'),
            email = user.get('email'),
            hash = _this.get('session.claims.ic'),
            user_id = _this.get('session.claims.sub');
        intercom.set('user.name', name);
        intercom.set('user.email', email);
        intercom.set('user.user_hash', hash);
        intercom.set('user.user_id', user_id);

        var attrs = {
          email: email,
          name: name,
          user_id: user_id,
          user_hash: hash
        };
        intercom.update(attrs);
        intercom.start();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.get('intercom').stop();
    },


    actions: {
      logout: function logout() {
        get(this, 'doLogout')();
      }
    }
  });
});