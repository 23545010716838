define('fitbase/auth/register/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var get = Ember.get;
  exports.default = Route.extend(_unauthenticatedRouteMixin.default, {
    model: function model() {
      return this.store.createRecord('user');
    },


    actions: {
      doRegister: function doRegister() {
        var _this = this;

        var model = get(this, 'currentModel');
        model.save().then(function () {
          var session = get(_this, 'session');
          var email = get(model, 'email');
          var password = get(model, 'password');
          return session.authenticate('authenticator:application', email, password);
        }).catch(function () {
          console.error('There was a problem logging in the registered user');
        });
      }
    }
  });
});