define('fitbase/helpers/text-highlight', ['exports', 'ember-text-highlight/helpers/text-highlight', 'ember-text-highlight/-private/indices-implementation', 'ember-text-highlight/-private/regex-implementation'], function (exports, _textHighlight, _indicesImplementation, _regexImplementation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _textHighlight.default;
    }
  });
  Object.defineProperty(exports, 'indicesImplementation', {
    enumerable: true,
    get: function () {
      return _indicesImplementation.default;
    }
  });
  Object.defineProperty(exports, 'regexImplementation', {
    enumerable: true,
    get: function () {
      return _regexImplementation.default;
    }
  });
});