define('fitbase/client/model', ['exports', 'ember-data', 'ember-md5'], function (exports, _emberData, _emberMd) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var isEmpty = Ember.isEmpty;
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    name: attr('string'),
    email: attr('string'),
    notes: attr('string'),
    state: attr('string'),
    stateUpdatedAt: attr('date'),
    avatar: attr('string'),
    icsDtend: attr('date'),
    lastCoachedAt: attr('date'),
    lastMessageAt: attr('date'),
    hasUncoachedMessage: attr('boolean'),

    checkinForm: belongsTo('checkin-form'),
    user: belongsTo('user'),
    checkinLatest: belongsTo('checkin'),
    checkins: hasMany('checkin'),

    firstName: computed('name', function () {
      var name = this.get('name');
      if (name) {
        return name.split(' ')[0];
      }
    }),

    isEnded: computed('icsDtend', function () {
      var icsDtend = this.get('icsDtend');
      var now = new Date();
      return icsDtend < now && icsDtend !== null;
    }),

    avatarUrl: computed('avatar', 'email', function () {
      var avatar = this.get('avatar');
      if (isEmpty(avatar)) {
        var email = this.get('email').toLowerCase();
        var hash = (0, _emberMd.default)(email);
        return 'https://www.gravatar.com/avatar/' + hash + '?d=blank';
      }
    })
  });
});