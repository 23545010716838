define('fitbase/services/intercom', ['exports', 'ember-intercom-io/services/intercom', 'fitbase/config/environment'], function (exports, _intercom, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getWithDefault = Ember.getWithDefault;
  exports.default = _intercom.default.extend({
    config: getWithDefault(_environment.default, 'intercom', {})
  });
});