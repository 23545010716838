define('fitbase/coach/clients/new/controller', ['exports', 'fitbase/utils/client-validations', 'ember-cp-validations'], function (exports, _clientValidations, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var isEmpty = Ember.isEmpty;


  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.name': _clientValidations.fullname,
    'model.email': _clientValidations.email
  });

  exports.default = Controller.extend(Validations, {
    showValidations: false,
    clientName: computed('model.name', function () {
      return isEmpty(this.get('model.name')) ? 'New Client' : this.get('model.name');
    }),

    firstName: computed('model.firstName', function () {
      return isEmpty(this.get('model.firstName')) ? 'client' : this.get('model.firstName');
    }),

    placeholderNote: computed('firstName', function () {
      return 'Some key things to remember about ' + this.get('firstName') + '.';
    }),

    actions: {
      save: function save() {
        if (this.get('validations.isValid')) {
          return true;
        } else {
          this.set('showValidations', true);
        }
      },
      cancelClient: function cancelClient() {
        window.history.back();
      }
    }
  });
});