define('fitbase/components/checkin-history-table/component', ['exports', 'fitbase/utils/field-units'], function (exports, _fieldUnits) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var htmlSafe = Ember.String.htmlSafe;
  var filter = Ember.computed.filter;


  var unitFields = _fieldUnits.default.lookupList('inputType');

  exports.default = Component.extend({
    tagName: 'table',
    classNames: 'checkin-history__table',
    fieldsSorted: computed('fields.[]', function () {
      if (get(this, 'fields')) {
        return get(this, 'fields').sortBy('sequence', 'id');
      }
    }),

    numberFields: filter('fieldsSorted.[]', function (f) {
      return unitFields[get(f, 'unit')] === "number";
    }),

    hasNoteFields: computed('fields.[]', function () {
      if (get(this, 'fields')) {
        return get(this, 'fields').any(function (item) {
          return ["note", "text"].indexOf(unitFields[get(item, 'unit')]) >= 0;
        });
      } else {
        return false;
      }
    }),

    colspan: computed('numberfields.[]', function () {
      var numFieldLen = get(this, 'numberFields.length');
      var colCount = numFieldLen ? numFieldLen + 1 : 2;
      return htmlSafe(colCount);
    }),

    colwidth: computed('colspan', function () {
      return htmlSafe('width: ' + 100 / get(this, 'colspan') + '%');
    })

  });
});