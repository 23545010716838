define('fitbase/checkin-form/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    nextAt: attr('date'),
    frequency: attr('number'),

    client: belongsTo('client'),
    checkinFields: hasMany('checkin-field'),
    fieldsOrder: attr()
  });
});