define('fitbase/components/input-file/component', ['exports', 'ember-uploader', 'fitbase/config/environment'], function (exports, _emberUploader, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty;
  var $ = Ember.$;
  var getOwner = Ember.getOwner;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = _emberUploader.default.FileField.extend({
    filePrefix: null,
    signingUrl: _environment.default.DS.host + '/' + _environment.default.DS.namespace + '/upload-signatures',
    session: computed(function () {
      return getOwner(this).lookup('service:session');
    }),

    folder: computed('checkinId', function () {
      return encodeURIComponent('checkin-images/' + get(this, 'checkinId'));
    }),

    change: function change(event) {
      this.get('on-change')(event);
      this._super.call(this, event);
    },
    focusIn: function focusIn() {},
    filesDidChange: function filesDidChange(files) {
      var _this = this,
          _arguments = arguments;

      var uploader = _emberUploader.default.S3Uploader.create({
        signingUrl: this.get('signingUrl'),
        signingMethod: 'POST',
        signingAjaxSettings: {
          headers: { "Authorization": 'Bearer ' + get(this, 'session.data.authenticated.access_token') }
        }
      });

      uploader.on('didUpload', function (response) {
        var uploadedUrl = $(response).find('Location')[0].textContent; // S3 will return XML with url
        uploadedUrl = decodeURIComponent(uploadedUrl);
        get(_this, 'on-upload')(uploadedUrl);
      });

      uploader.on('progress', function (e) {
        if (get(_this, 'on-progress')) {
          get(_this, 'on-progress')(e);
        }
      });

      uploader.on('didError', function () /*jqXHR, textStatus, errorThrown*/{
        var _get;

        // Handle unsuccessful upload
        (_get = _this.get('raven')).captureMessage.apply(_get, _arguments);
        if (get(_this, 'on-error')) {
          get(_this, 'on-error').apply(undefined, _arguments);
        }
      });

      if (!isEmpty(files)) {
        // Send a sign request then upload to S3
        // this second argument is optional and can to be sent as extra data with the upload
        var folder = get(this, 'folder');
        var filePrefix = get(this, 'filePrefix');
        var prefix = filePrefix ? filePrefix + '-' : '';
        var modelId = get(this, 'modelId');

        uploader.upload(files[0], {
          folder: folder + '/' + modelId,
          prefix: prefix
        });
      }
    }
  });
});