define('fitbase/lib/fields-map-old', ['exports', 'fitbase/lib/field-schema'], function (exports, _fieldSchema) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ArrayProxy = Ember.ArrayProxy;
  var isNone = Ember.isNone;
  var isArray = Ember.isArray;
  var computed = Ember.computed;


  var JSONArray = ArrayProxy.extend({
    hasInvalidFields: computed('content.@each.isInvalid', function () {
      return this.any(function (field) {
        return field.get('isInvalid');
      });
    }),
    clean: function clean() {
      var content = this.get('content');

      if (isArray(content)) {
        var filteredContent = content.filterBy('isValid', true);
        this.set('content', filteredContent);
      }
    },
    toString: function toString() {
      return JSON.stringify(this.get('content'));
    },
    addOne: function addOne(obj) {
      var field = void 0;
      if (isNone(obj)) {
        field = _fieldSchema.default.create();
      } else {
        field = _fieldSchema.default.create(obj);
      }

      this.pushObject(field);
    }
  });

  JSONArray.reopenClass({
    create: function create() {
      var A = arguments.length <= 0 ? undefined : arguments[0];

      if (isNone(arguments.length <= 0 ? undefined : arguments[0])) {
        A = [];
      }
      if (isArray(A)) {
        return this._super({ content: arguments.length <= 0 ? undefined : arguments[0] });
      }

      throw Error;
    },
    fromJson: function fromJson(jsonString) {
      var pojo = void 0,
          schemas = void 0;

      if (isNone(jsonString)) {
        return null;
      }

      try {
        pojo = JSON.parse(jsonString);
      } catch (err) {
        pojo = null;
      }

      if (!isNone(pojo) && isArray(pojo)) {
        schemas = pojo.map(function (f) {
          return _fieldSchema.default.create(f);
        });
      }

      return this.create(schemas);
    }
  });

  exports.default = JSONArray;
});