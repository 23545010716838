define('fitbase/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'fitbase/config/environment', 'ember-data'], function (exports, _applicationRouteMixin, _environment, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Route = Ember.Route;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Route.extend(_applicationRouteMixin.default, {
    routeAfterAuthentication: computed('session.subject', function () {
      var _get = get(this, 'session.subject'),
          _get2 = _slicedToArray(_get, 2),
          model = _get2[0],
          id = _get2[1];

      var start_path = get(this, 'session.data.start_path');

      switch (model) {
        case 'checkin':
          return '/checkin/' + id;
        case 'client':
          if (start_path) {
            return start_path;
          } else {
            return '/checkins';
          }
        default:
          return '/coach/clients';
      }
    }).volatile(),

    sessionAuthenticated: function sessionAuthenticated() {
      if (_environment.default.environment !== "test") {
        get(this, 'session').identify();
      }
      return this._super.apply(this, arguments);
    },
    sessionInvalidated: function sessionInvalidated() {
      if (_environment.default.environment !== "test") {
        get(this, 'session').unidentify();
      }
      return this._super.apply(this, arguments);
    },


    actions: {
      logout: function logout() {
        get(this, 'session').invalidate();
      },
      error: function error(reason) {
        if (reason instanceof _emberData.default.AdapterError && reason.errors && reason.errors[0].status == 404) {
          this.transitionTo('/not-found');
        }
      }
    }
  });
});