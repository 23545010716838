define('fitbase/components/checkin-field/component', ['exports', 'ember-gestures/mixins/recognizers', 'fitbase/utils/field-units'], function (exports, _recognizers, _fieldUnits) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var equal = Ember.computed.equal;
  var not = Ember.computed.not;
  var Component = Ember.Component;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend(_recognizers.default, {
    recognizers: 'tap',
    tagName: 'li',
    classNames: ['checkin-input'],
    classNameBindings: ['isPrev:checkin-input--prev', 'isCurrent:checkin-input--current', 'isNext:checkin-input--next'],
    schema: alias('checkinField'),
    inputName: computed('checkinField.id', function () {
      return 'field-' + get(this, 'checkinField.id');
    }),
    checkinValue: alias('checkinField.checkinValue'),
    value: alias('checkinValue.value'),
    bufferValue: undefined,
    label: alias('schema.text'),
    unit: computed('schema.unit', function () {
      var unit = get(this, 'schema.unit');
      return get(this, 'fieldUnitSuffixes')[unit];
    }),
    fieldUnitSuffixes: _fieldUnits.default.lookupList('suffix'),
    fieldInputTypes: _fieldUnits.default.lookupList('inputType'),
    inputType: computed('schema.unit', function () {
      return this.get('fieldInputTypes')[this.get('schema.unit')];
    }),
    isNumber: equal('inputType', 'number'),
    isText: equal('inputType', 'text'),
    isTextarea: equal('inputType', 'note'),
    isFeeling: equal('inputType', 'feeling'),
    isImage: equal('inputType', 'image'),

    isPrev: computed('fieldIndex', 'prevFieldIndex', function () {
      return this.get('fieldIndex') === this.get('prevFieldIndex');
    }),
    isCurrent: computed('fieldIndex', 'currentFieldIndex', function () {
      return this.get('fieldIndex') === this.get('currentFieldIndex');
    }),
    isNext: computed('fieldIndex', 'nextFieldIndex', function () {
      return this.get('fieldIndex') === this.get('nextFieldIndex');
    }),

    isDisabled: not('isCurrent'),

    hasPrev: computed('prevFieldIndex', function () {
      return this.get('prevFieldIndex') !== false;
    }),
    hasNext: computed('nextFieldIndex', function () {
      return this.get('nextFieldIndex') !== false;
    }),

    actions: {
      next: function next() {
        this._save();this.get('onNext')();this._blur();
      },
      prev: function prev() {
        this._save();this.get('onPrev')();this._blur();
      },
      save: function save() {
        return this._save();
      },
      update: function update(newValue) {
        set(this, 'bufferValue', newValue);
        if (this.get('checkinValue.content')) {
          set(this, 'value', newValue);
        }
      },
      showSummary: function showSummary() {
        var _this = this;

        this._save().then(function () {
          _this.get('showSummary')();
        });
      }
    },

    // _private methods
    _save: function _save() {
      var checkinValue = this.get('checkinValue'),
          bufferValue = this.get('bufferValue'),
          checkinField = this.get('checkinField');

      return this.get('save')(checkinValue, bufferValue, checkinField);
    },
    _focus: function _focus() {
      this.$('.checkin-input__elem').focus();
    },
    _blur: function _blur() {
      this.$(':focus').blur();
    }
  });
});