define("fitbase/transforms/delta", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return JSON.parse(serialized);
    },
    serialize: function serialize(deserialized) {
      if (deserialized && typeof deserialized !== "string") {
        return JSON.stringify(deserialized);
      }
      return deserialized;
    }
  });
});