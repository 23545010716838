define('fitbase/coach/clients/client/edit/index/controller', ['exports', 'fitbase/utils/client-validations', 'ember-cp-validations'], function (exports, _clientValidations, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal;
  var Controller = Ember.Controller;


  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.name': _clientValidations.fullname,
    'model.email': _clientValidations.email
  });

  exports.default = Controller.extend(Validations, {
    confirmingArchive: false,
    isArchived: equal('model.state', 'archived'),
    showValidations: false,

    actions: {
      promptArchive: function promptArchive() {
        this.set('confirmingArchive', true);
      },
      save: function save() {
        if (this.get('validations.isValid')) {
          return true;
        } else {
          this.set('showValidations', true);
        }
      }
    }
  });
});