define('fitbase/mixins/authenticated-trainer-route-mixin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Mixin = Ember.Mixin;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Mixin.create(_authenticatedRouteMixin.default, {
    authenticationRoute: 'auth.login',
    isAuthenticated: computed('session.{subject,isAuthenticated}', function () {
      var isAuthed = get(this, 'session.isAuthenticated');
      if (isAuthed) {
        var _get = get(this, 'session.subject'),
            _get2 = _slicedToArray(_get, 1),
            model = _get2[0];

        return model === 'user';
      } else {
        return false;
      }
    }),

    beforeModel: function beforeModel() {
      if (!get(this, 'isAuthenticated')) {
        if (get(this, 'session.isAuthenticated')) {
          get(this, 'session').invalidate();
        }
        this._super.apply(this, arguments);
      }
    }
  });
});