define('fitbase/checkin-reply/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    recipient: attr('string'),
    body: attr('string'),
    sentAt: attr('date'),
    insertedAt: attr('date'),
    author: attr('string'),

    checkin: belongsTo('checkin'),
    client: belongsTo('client'),
    user: belongsTo('user')
  });
});