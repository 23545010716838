define('fitbase/user/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hasMany = _relationships.default.hasMany;
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    username: (0, _attr.default)('string'),
    uuid: (0, _attr.default)('string'),
    password: (0, _attr.default)('string'),
    confirmPassword: (0, _attr.default)('string'),
    currentPassword: (0, _attr.default)('string'),
    clients: hasMany('clients')
  });
});