define('fitbase/checkin/route', ['exports', 'fitbase/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty;
  var Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    authenticationRoute: "auth.register",

    model: function model(params) {
      var id = params.id,
          include = "checkin-values,client";

      return this.store.findRecord('checkin', id, { include: include, reload: true });
    },
    afterModel: function afterModel() {
      var _this = this;

      var checkin = this.routeModel();
      if (this.get('session.subjectType') === 'user') {
        checkin.set('isReviewing', true);
      }

      // This section loads up built value models on the fields if there are none
      // Currently required to be able to have value objects to set the values on
      // as their properties change
      // Progress bar updates on input change,
      // but is saved when clicking previous, next or summary
      checkin.get('checkinForm').then(function (checkinForm) {
        return checkinForm.get('checkinFields');
      }).then(function (checkinFields) {
        checkinFields.forEach(function (checkinField) {
          if (isEmpty(checkinField.get('checkinValue.content'))) {
            checkinField.set('checkinValue', _this.store.createRecord('checkinValue', { checkin: checkin, checkinField: checkinField }));
          }
        });
      });
    },
    routeModel: function routeModel() {
      return this.modelFor(this.routeName);
    },
    checkinComplete: function checkinComplete() {
      return this.routeModel().get('completedAndSaved');
    },


    actions: {
      savePosition: function savePosition(index) {
        this.routeModel().set('currentIndexView', index);
        if (this.checkinComplete()) {
          return false;
        }
        this.routeModel().save();
      },
      save: function save(checkinValue, value, checkinField) {
        var checkin = this.routeModel();
        checkinValue = checkinValue.get('content');
        checkinValue.setProperties({ value: value, checkin: checkin, checkinField: checkinField });
        return checkinValue.save();
      },
      submit: function submit() {
        var _this2 = this;

        var checkin = this.routeModel();
        var isNotUser = this.get('session.subjectType') !== "user";
        if (isNotUser) {
          if (this.checkinComplete()) {
            return false;
          }
          checkin.set('completedAt', new Date());
          return checkin.save();
        } else {
          return checkin.save().then(function () {
            return _this2.transitionTo('coach.clients.client.checkins.index', checkin.get('client.id'));
          });
        }
      },
      showSummary: function showSummary() {
        var checkin = this.routeModel();
        checkin.set('isReviewing', true);
        //let currentFieldIndex = checkin.get('currentIndexView');
        //let checkinValue = checkin
        //.get('checkinForm.checkinFields')
        //.objectAt(currentFieldIndex)
        //.get('checkinValue');
        //checkinValue.then((checkinValue) => {
        //checkinValue.set('checkin', checkin);
        //checkinValue.save();
        //checkin.set('isReviewing', true);
        //});
      }
    }
  });
});