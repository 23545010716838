define('fitbase/components/input-control/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    attributeBindings: ['displayError:data-error'],
    classNames: ['input', 'input--layout'],
    classNameBindings: ['isInvalid:input--invalid'],
    error: null,
    displayError: computed('error', 'showValidations', 'validationError', function () {
      if (this.get('showValidations')) {
        return this.get('validationError');
      } else {
        return this.get('error');
      }
    }),
    isInvalid: computed('displayError', function () {
      return !!this.get('displayError');
    })
  });
});