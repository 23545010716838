define('fitbase/coach/settings/route', ['exports', 'fitbase/mixins/authenticated-trainer-route-mixin'], function (exports, _authenticatedTrainerRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;
  exports.default = Route.extend(_authenticatedTrainerRouteMixin.default, {

    session: service(),
    model: function model() {
      return this.store.findRecord('user', 'current');
    },

    deactivate: function deactivate() {
      var user = this._routeModel();
      if (user.get('hasDirtyAttributes')) {
        user.rollbackAttributes();
      }
    },
    _routeModel: function _routeModel() {
      return this.modelFor(this.routeName);
    }
  });
});