define('fitbase/components/input-date/component', ['exports', 'ember-pikaday/components/pikaday-input'], function (exports, _pikadayInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _pikadayInput.default.extend({
    touchStart: function touchStart() {
      this.set('touchMoved', false);
    },
    touchMove: function touchMove() {
      this.set('touchMoved', true);
    },
    touchEnd: function touchEnd(e) {
      if (this.get('touchMoved')) {
        e.stopPropagation();
      }
    }
  });
});