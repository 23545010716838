define('fitbase/components/latest-reply/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    session: service(),
    isExpanded: false,
    classNames: ['latest-reply'],
    classNameBindings: ['isExpanded:latest-reply--expanded', 'authorCss'],

    authorCss: computed('reply.author', function () {
      var author = this.reply.get('author') || 'user';

      return 'is-' + author;
    }),

    author: computed('reply.{author,client,user}', 'session.currentUser', function () {
      var authorString = get(this, 'reply.author') == 'client' ? 'client' : 'user';
      return get(this, 'reply.' + authorString);
    }),

    authorIsSelf: computed('reply.author', 'session.subjectType', function () {
      return get(this, 'session.subjectType') == get(this, 'reply.author');
    }),

    authorName: computed('author', function () {
      if (this.authorIsSelf) {
        return 'you';
      } else {
        return this.author.get('name');
      }
    }),

    click: function click() {
      this.toggleProperty('isExpanded');
    }
  });
});