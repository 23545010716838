define('fitbase/coach/clients/client/edit/checkin-form/controller', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var sort = Ember.computed.sort;
  var A = Ember.A;
  var Controller = Ember.Controller;
  var set = Ember.set;
  var computed = Ember.computed;
  var get = Ember.get;
  var alias = computed.alias;
  exports.default = Controller.extend({
    init: function init() {
      this.checkinFieldsSortOrder = ["sequence", "id"];
      this._super.apply(this, arguments);
    },


    today: (0, _moment.default)().add(1, "days").hours(0).minutes(0).seconds(0).milliseconds(0).toDate(),

    minEndAt: computed('model.{nextAt,frequency}', function () {
      var nextAt = this.get('model.nextAt');
      var frequency = this.get('model.frequency') || 1;
      if (nextAt) {
        return (0, _moment.default)(nextAt).add(frequency, "days").hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
      } else {
        return this.get('today');
      }
    }),

    nextAt: computed.alias('model.nextAt'),

    checkinFrequencyOptions: A([{ label: "Daily", value: 1 }, { label: "Weekly", value: 7 }, { label: "Every 2 weeks", value: 14 }, { label: "Every 4 weeks", value: 28 }, { label: "Every 6 weeks", value: 42 }]),

    client: alias('model.client'),
    checkinForm: alias('model'),
    checkinFields: sort('checkinForm.checkinFields', 'checkinFieldsSortOrder'),
    isSorting: false,

    shouldSave: computed('checkinForm.{hasDirtyAttributes,shouldSave}', function () {
      return get(this, 'checkinForm.hasDirtyAttributes') || get(this, 'checkinForm.shouldSave');
    }),

    nextSequence: computed('checkinForm.checkinFields.[]', function () {
      return get(this, 'checkinForm.checkinFields.length') + 1;
    }),

    classSaveButton: computed('checkinForm.hasDirtyAttributes', function () {
      return get(this, 'checkinForm.hasDirtyAttributes') ? 'button--primary' : '';
    }),

    saveButtonText: computed('checkinForm.hasDirtyAttributes', function () {
      return get(this, 'checkinForm.hasDirtyAttributes') ? 'save' : 'done';
    }),

    actions: {
      reorderFields: function reorderFields(fields) {
        set(this, 'checkinFieldsSorted', fields);
      },
      startSorting: function startSorting() {
        this.get('checkinFields').filterBy('isNew', true).invoke('destroyRecord');
        var fieldsCopy = A(get(this, 'checkinFields').map(function (m) {
          return m;
        }));
        set(this, 'checkinFieldsSorted', fieldsCopy);
        set(this, 'isSorting', true);
      },
      cancelSorting: function cancelSorting() {
        set(this, 'isSorting', false);
      },
      saveSorting: function saveSorting() {
        var _this = this;

        var fields = get(this, 'checkinFieldsSorted');
        var order = fields.map(function (field, i) {
          return { id: get(field, "id"), sequence: i };
        });
        var checkinForm = get(this, 'checkinForm');
        checkinForm.setProperties({ checkinFields: fields, fieldsOrder: order });
        checkinForm.save().then(function () {
          return _this.set('isSorting', false);
        });
      },
      save: function save() {
        return true;
      },
      disableDayFn: function disableDayFn(date) {
        var frequency = this.get('model.frequency');
        var nextAt = this.get('model.nextAt');

        var diff = (0, _moment.default)(nextAt).diff(date, 'days');
        return diff > 0 || diff % frequency !== 0;
      }
    }
  });
});