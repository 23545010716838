define('fitbase/coach/settings/security/controller', ['exports', 'fitbase/utils/user-validations', 'ember-cp-validations'], function (exports, _userValidations, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;


  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.password': _userValidations.editPassword
  });

  exports.default = Controller.extend(Validations, {
    showValidations: false,
    actions: {
      save: function save() {
        if (this.get('validations.isValid')) {
          return true;
        } else {
          this.set('showValidations', true);
        }
      }
    }
  });
});