define("fitbase/utils/field-units", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.byInputType = byInputType;
  exports.lookupList = lookupList;
  exports.selectOptions = selectOptions;
  var units = exports.units = [{
    unit: "inches",
    suffix: "in",
    label: "Inches (in)",
    inputType: "number"
  }, {
    unit: "cm",
    suffix: "cm",
    label: "Centimeters (cm)",
    inputType: "number"
  }, {
    unit: "pounds",
    suffix: "lbs",
    label: "Pounds (lbs)",
    inputType: "number"
  }, {
    unit: "kilograms",
    suffix: "kgs",
    label: "Kilos (kgs)",
    inputType: "number"
  }, {
    unit: "text",
    suffix: "",
    label: "Short text",
    inputType: "text"
  }, {
    unit: "note",
    suffix: "",
    label: "Notes",
    inputType: "note"
  }, {
    unit: "image",
    suffix: "",
    label: "a Picture 📷",
    inputType: "image"
  }, {
    unit: "minutes",
    suffix: "mins",
    label: "Minutes",
    inputType: "number"
  }, {
    unit: "hours",
    suffix: "hrs",
    label: "Hours",
    inputType: "number"
    //}, {
    //unit: "feelings",
    //suffix: "",
    //label: "Feelings note (-/+)",
    //inputType: "rating",
    //}, {
    //unit: "date",
    //suffix: "",
    //label: "Date",
    //inputType: "date",
  }];

  // Returns an array of units
  function byInputType(type) {
    return units.reduce(function (a, b) {
      if (b.inputType === type) {
        a.push(b.unit);
      }
      return a;
    }, []);
  }

  // Returns an Object {}
  function lookupList(key) {
    return units.reduce(function (a, b) {
      a[b.unit] = b[key];return a;
    }, {});
  }

  function selectOptions(label) {
    return units.map(function (unit) {
      return { value: unit.unit, label: unit[label] };
    });
  }
});