define('fitbase/application/authenticator', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'fitbase/config/environment'], function (exports, _oauth2PasswordGrant, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _oauth2PasswordGrant.default.extend({
    session: service(),
    serverTokenEndpoint: _environment.default.DS.host + '/' + _environment.default.DS.namespace + '/token',
    serverTokenRevocationEndpoint: _environment.default.DS.host + '/' + _environment.default.DS.namespace + '/revoke'
  });
});