define('fitbase/checkin/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var computed = Ember.computed;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    currentIndexView: attr('number'),
    dueAt: attr('date'),
    completedAt: attr('date'),
    updatedAt: attr('date'),
    token: attr('string'),

    // relationships
    client: belongsTo('client', { inverse: 'checkins' }),
    checkinForm: belongsTo('checkinForm'),
    checkinFieldValues: hasMany('checkin-field-value'),
    checkinValues: hasMany('checkin-value'),
    checkinReplies: hasMany('checkin-reply'),
    checkinReplyDraft: computed('checkinReplies.@each.sentAt', function () {
      return get(this, 'checkinReplies').findBy('sentAt', null);
    }),

    completedAndSaved: computed('completedAt', 'hasDirtyAttributes', function () {
      var completed = get(this, 'completedAt');
      var dirty = get(this, 'hasDirtyAttributes');
      return completed && !dirty;
    })
  });
});