define('fitbase/components/checkin-confirm-field/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal;
  var isEmpty = Ember.isEmpty;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['checkin-confirm__field-value'],
    classNameBindings: ['checkinField.unit', 'isEmpty'],
    imgixQuery: "?fit=crop&w=340&h=340",
    isImage: equal('checkinField.unit', 'image'),
    isEmpty: computed('checkinField.checkinValue.value', function () {
      return isEmpty(get(this, 'checkinField.checkinValue.value'));
    }),

    click: function click() {
      get(this, 'goToField')(get(this, 'checkinField'));
    }
  });
});