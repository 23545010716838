define('fitbase/checkin-field-value/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    label: attr('string'),
    value: attr('string'),
    unit: attr('string'),
    delta: attr('delta'),
    inputType: attr('string'),
    sequence: attr('number'),
    createdAt: attr('string'),

    checkin: belongsTo('checkin'),

    deltaSign: computed('delta', function () {
      if (this.get('delta') < 0) {
        return 'is-negative';
      }
      if (this.get('delta') > 0) {
        return 'is-positive';
      }
      return 'is-neutral';
    })

  });
});