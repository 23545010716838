define('fitbase/components/checkins-li/component', ['exports', 'ember-click-outside/mixin'], function (exports, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var sort = Ember.computed.sort;
  var filterBy = Ember.computed.filterBy;
  var filter = Ember.computed.filter;
  var or = Ember.computed.or;
  var not = Ember.computed.not;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var next = Ember.run.next;
  var later = Ember.run.later;
  exports.default = Component.extend(_mixin.default, {
    init: function init() {
      this.sort = ["sequence:asc"];
      this._super.apply(this, arguments);
    },

    classNames: ['checkins-li'],
    classNameBindings: ['isShowingNotes', 'isShowingExpanded', 'isIncomplete:checkins-li--incomplete', 'hasNotes:checkins-li--has-notes', 'hasReplies:checkins-li--has-replies', 'hasExpandedData:checkins-li--has-expand'],
    checkinDate: alias('checkin.dueAt'),
    checkinFieldValuesSorted: sort('checkinFieldValues', 'sort'),
    checkinNumbers: filterBy('checkinFieldValuesSorted', 'inputType', 'number'),
    checkinNotes: filter('checkinFieldValuesSorted', function (f) {
      return ["text", "note"].indexOf(get(f, 'inputType')) >= 0;
    }),
    hasNotes: computed('checkinNotes.length', function () {
      return !!get(this, 'checkinNotes.length');
    }),
    hasReplies: computed('checkinReplies.length', function () {
      return !!get(this, 'checkinReplies.length');
    }),
    checkinReplies: computed('checkin.checkinReplies.[]', function () {
      return this.get('checkin.checkinReplies').sortBy('sentAt');
    }),
    hasExpandedData: or('hasReplies', 'hasNotes'),
    isShowingReplies: false,
    isShowingNotes: alias('isShowingExpanded'),
    isShowingActions: false,
    isIncomplete: not('checkin.completedAt'),
    checkinLink: computed('checkin.{id,token}', function () {
      var link = document.createElement('a');
      link.href = window.location;
      link.pathname = '/checkin-link/' + get(this, 'checkin.id') + '/' + get(this, 'checkin.token');
      return link.href;
    }),

    actions: {
      didClickNotes: function didClickNotes() {
        this.set('isShowingActions', false);
      },
      didClickToggleReplies: function didClickToggleReplies() {
        this.toggleProperty('isShowingReplies');
      },
      didClickCopyLink: function didClickCopyLink() {
        var _this = this;

        var el = document.querySelector('.checkin-action__url');
        var oldContentEditable = el.contentEditable,
            oldReadOnly = el.readOnly,
            range = document.createRange();

        el.contenteditable = true;
        el.readonly = false;
        range.selectNodeContents(el);

        var s = window.getSelection();
        s.addRange(range);

        el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

        el.contentEditable = oldContentEditable;
        el.readOnly = oldReadOnly;

        if (document.execCommand('copy')) {
          document.execCommand("unselect");
          this.set('didCopyLink', true);
          later(function () {
            _this.set('isShowingActions', false);
            _this.set('didCopyLink', false);
          }, 700);
        }
      },
      didClickNumbers: function didClickNumbers() {
        this.set('isShowingActions', false);
        if (!get(this, 'hasExpandedData')) {
          return;
        }
        this.toggleProperty("isShowingExpanded");
        this.set('isShowingReplies', false);
      },
      showActions: function showActions() {
        this.toggleProperty('isShowingActions');
      }
    },

    clickOutside: function clickOutside() {
      this.set('isShowingExpanded', false);
      this.set('isShowingReplies', false);
      this.set('isShowingActions', false);
    },
    didInsertElement: function didInsertElement() {
      next(this, this.addClickOutsideListener);
    },
    willDestroyElement: function willDestroyElement() {
      this.removeClickOutsideListener();
    }
  });
});