define('fitbase/components/pics-column-reel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var htmlSafe = Ember.String.htmlSafe;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['pics-column__reel'],
    attributeBindings: ['style'],
    imageCheckinValues: null,
    reelTop: 0,
    imgixQuery: "?fit=crop&w=340&h=544",

    style: computed('reelTop', function () {
      var reelTop = get(this, 'reelTop');
      return htmlSafe('transform: translateY(' + reelTop + '%);');
    })

  });
});