define('fitbase/utils/client-validations', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.email = exports.fullname = undefined;
  var fullname = exports.fullname = [(0, _emberCpValidations.validator)('presence', {
    presence: true,
    ignoreBlank: true,
    message: "Please enter a name"
  })];

  var email = exports.email = [(0, _emberCpValidations.validator)('presence', {
    presence: true,
    ignoreBlank: true,
    message: "Please enter an email"
  }), (0, _emberCpValidations.validator)('format', {
    type: 'email',
    allowBlank: true,
    message: "Needs to be a valid email address"
  })];

  exports.default = {
    fullname: fullname, email: email
  };
});