define('fitbase/coach/clients/client/checkins/pictures/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.modelFor('coach.clients.client.checkins');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.setProperties({
        clients: this.modelFor('coach.clients')
      });
    },
    deactivate: function deactivate() {
      this.controller.set('selectedField', null);
    }
  });
});