define('fitbase/session/service', ['exports', 'ember-data', 'ember-simple-auth/services/session', 'fitbase/config/environment'], function (exports, _emberData, _session, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var alias = Ember.computed.alias;
  var get = Ember.get;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = _session.default.extend({
    store: service(),
    intercom: service(),

    claims: computed('token', function () {
      var token = get(this, 'token');
      var payload = this._parsePayload(token);
      if (payload) {
        return payload;
      }
      return null;
    }),

    subject: computed('claims', function () {
      var sub = this.get('claims.sub');
      if (sub) {
        return sub.toLowerCase().split(':');
      }
      return null;
    }),

    subjectType: computed('subject', function () {
      var subject = get(this, 'subject');
      if (subject) {
        return get(this, 'subject')[0];
      }
    }),

    exp: computed('claims', function () {
      return this.get('claims.exp');
    }),

    token: alias('data.authenticated.access_token'),

    currentUser: computed('isAuthenticated', function () {
      if (get(this, 'isAuthenticated')) {
        var promise = get(this, 'store').findRecord('user', 'current');
        return _emberData.default.PromiseObject.create({ promise: promise });
      }
    }),

    currentSubject: computed('subject', 'isAuthenticated', function () {
      if (get(this, 'isAuthenticated')) {
        var _get = this.get('subject'),
            _get2 = _slicedToArray(_get, 2),
            subjectType = _get2[0],
            id = _get2[1];

        var store = get(this, 'store');

        if (subjectType === 'user') {
          var promise = store.findRecord('user', 'current');
          return _emberData.default.PromiseObject.create({ promise: promise });
        }
        if (subjectType === 'checkin') {
          var _promise = store.findRecord('checkin', id);
          return _emberData.default.PromiseObject.create({ promise: _promise });
        }
        if (subjectType === 'client') {
          var _promise2 = store.findRecord('client', id);
          return _emberData.default.PromiseObject.create({ promise: _promise2 });
        }
      }
    }),

    identify: function identify() {
      var _this = this;

      var sub = this.get('subject');

      var _sub = _slicedToArray(sub, 1),
          subjectType = _sub[0];

      var env_prefix = _environment.default.deployTarget !== "production" ? _environment.default.deployTarget + '_' : '';
      var identifier = '' + env_prefix + sub.join('_');

      return this.get('currentSubject').then(function (subject) {
        var attrs = {
          identifier: identifier,
          name: null,
          email: null,
          env: _environment.default.deployTarget
        };

        if (subjectType === "user") {
          attrs.name = get(subject, 'email');
          attrs.email = get(subject, 'email');
        }
        if (subjectType === "checkin") {
          attrs.name = get(subject, 'client.name');
          attrs.email = get(subject, 'client.email');
        }
        if (subjectType === "client") {
          attrs.name = get(subject, 'name');
          attrs.email = get(subject, 'email');
        }
        return _this._sendIdentities(attrs);
      });
    },
    unidentify: function unidentify() {
      if (_environment.default.environment === "test") {
        return;
      }
      this._resetIdentities();
    },
    _sendIdentities: function _sendIdentities(attrs) {
      var fullstory = window.FS || {
        identify: function identify() {
          console.log('Reset fullstory debug');
        }
      };
      var intercom = this.get('intercom');

      intercom.set('user.name', attrs.name);
      intercom.set('user.email', attrs.email);

      fullstory.identify(attrs.identifier, {
        displayName: attrs.name,
        email: attrs.email,
        env_str: attrs.env
      });
    },
    _resetIdentities: function _resetIdentities() {
      var fullstory = window.FS || {
        identify: function identify() {
          console.log('Reset fullstory debug');
        }
      };
      fullstory.identify(false);
      this.get('intercom').stop();
    },
    _parsePayload: function _parsePayload(token) {
      try {
        var parts = token.split('.');
        var payload = parts[1];
        if (payload) {
          return JSON.parse(atob(payload));
        } else {
          return false;
        }
      } catch (e) {
        console.warn('token', token, 'failed to be valid base64 encoding.');
        return false;
      }
    }
  });
});