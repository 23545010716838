define('fitbase/components/input-auth/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    component: 'input-auth',
    classNames: ["input", 'input--layout'],
    classNameBindings: ["isActive:input--active", "isInvalid:input--invalid"],
    attributeBindings: ['component:data-component', 'error:data-error'],
    isActive: computed('value', 'hasFocus', function () {
      var isActive = !isEmpty(this.get('value')) || this.get('hasFocus');
      return isActive;
    }),
    isInvalid: computed('errors.[]', 'error', function () {
      if (this.get('error')) {
        return true;
      }
      return this.get('errors.length') > 0 && this.get('value');
    }),
    inputClass: computed('value', 'errors.[]', function () {
      var valid = this.get('isInvalid') ? 'invalid' : 'valid';
      if (this.get('value')) {
        return 'validate ' + valid;
      }
    }),
    type: "text",
    errorMessages: computed("errors.[]", function () {
      return (this.get("errors") || []).join(', ');
    }),

    actions: {
      onFocusIn: function onFocusIn() {
        this.set('hasFocus', true);
      },
      onFocusOut: function onFocusOut() {
        this.set('hasFocus', false);
      }
    }
  });
});