define('fitbase/components/checkin-latest-number/component', ['exports', 'fitbase/utils/field-units'], function (exports, _fieldUnits) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;


  var suffixes = _fieldUnits.default.lookupList('suffix');

  exports.default = Component.extend({
    classNames: ['current-stat'],
    classNameBindings: ['isFirst:current-stat--main', 'isPositive:current-stat--positive', 'isNegative:current-stat--negative'],
    isFirst: equal('index', -1),
    isPositive: computed('fieldValue.delta', function () {
      return get(this, 'fieldValue.delta') > 0;
    }),
    isNegative: computed('fieldValue.delta', function () {
      return get(this, 'fieldValue.delta') < 0;
    }),
    deltaText: computed('fieldValue.delta', function () {
      var delta = Math.abs(get(this, 'fieldValue.delta'));
      var suffix = suffixes[get(this, 'fieldValue.unit')];
      if (delta === 0) {
        return "•••";
      }
      return delta + ' ' + suffix;
    }),
    deltaTitle: computed('fieldValue.delta', function () {
      if (!get(this, 'fieldValue.delta')) {
        return "No change";
      }
    })
  });
});