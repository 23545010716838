define('fitbase/initializers/flash-messages', ['exports', 'fitbase/config/environment', 'ember-cli-flash/utils/flash-message-options'], function (exports, _environment, _flashMessageOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var deprecate = Ember.deprecate;

  var INJECTION_FACTORIES_DEPRECATION_MESSAGE = '[ember-cli-flash] Future versions of ember-cli-flash will no longer inject the service automatically. Instead, you should explicitly inject it into your Route, Controller or Component with `Ember.inject.service`.';
  function initialize() {
    var application = arguments[1] || arguments[0];

    var _ref = _environment.default || {},
        flashMessageDefaults = _ref.flashMessageDefaults;

    var _ref2 = flashMessageDefaults || [],
        injectionFactories = _ref2.injectionFactories;

    var options = (0, _flashMessageOptions.default)(flashMessageDefaults);
    var shouldShowDeprecation = !(injectionFactories && injectionFactories.length);

    deprecate(INJECTION_FACTORIES_DEPRECATION_MESSAGE, shouldShowDeprecation, {
      id: 'ember-cli-flash.deprecate-injection-factories',
      until: '2.0.0'
    });

    options.injectionFactories.forEach(function (factory) {
      application.inject(factory, 'flashMessages', 'service:flash-messages');
    });
  }

  exports.default = {
    name: 'flash-messages',
    initialize: initialize
  };
});