define('fitbase/coach/clients/route', ['exports', 'fitbase/mixins/authenticated-trainer-route-mixin'], function (exports, _authenticatedTrainerRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_authenticatedTrainerRouteMixin.default, {
    model: function model() {
      return this.store.findAll('client', { include: "checkin-forms" });
    }
  });
});