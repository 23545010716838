define('fitbase/components/checkin-latest-pics/component', ['exports', 'fitbase/utils/field-units'], function (exports, _fieldUnits) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var filter = Ember.computed.filter;
  var htmlSafe = Ember.String.htmlSafe;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;


  var unitFields = _fieldUnits.default.lookupList('inputType');

  exports.default = Component.extend({
    classNames: ["spaces__space"],
    client: null,
    checkin: null,
    imgixQuery: "?fit=crop&w=340&h=544",
    isVisible: computed('checkinPictures', function () {
      return !!get(this, 'checkinPictures.length');
    }),
    checkinPictures: filter('checkin.checkinFieldValues', function (f) {
      return unitFields[get(f, 'unit')] === 'image';
    }),
    currentPicIndex: 0,
    animate: true,
    isFirstPicSet: computed('currentPicIndex', function () {
      return get(this, 'currentPicIndex') === 0;
    }),
    isLastPicSet: computed('currentPicIndex', 'checkinPictures.[]', function () {
      return get(this, 'currentPicIndex') === get(this, 'checkinPictures.length') - 1;
    }),
    shiftLeft: computed('currentPicIndex', 'picSetWidth', 'animate', function () {
      var left = get(this, 'currentPicIndex') * get(this, 'picSetWidth') * -1;
      var animate = get(this, 'animate') ? "transition: transform .2s" : '';

      return htmlSafe('transform: translateX(' + left + 'px); ' + animate);
    }),

    actions: {
      nextPic: function nextPic() {
        if (!get(this, 'isLastPicSet')) {
          this.incrementProperty('currentPicIndex');
        }
      },
      prevPic: function prevPic() {
        if (!get(this, 'isFirstPicSet')) {
          this.decrementProperty('currentPicIndex');
        }
      }
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.set('animate', false);
      this.set('currentPicIndex', 0);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      var width = this.$('.client-pics').outerWidth();
      this.set('picSetWidth', width);
      this.set('animate', true);
    }
  });
});