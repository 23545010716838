define('fitbase/components/checkin-field-image/component', ['exports', 'ember-gestures/mixins/recognizers'], function (exports, _recognizers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = Component.extend(_recognizers.default, {
    tagName: '',
    imgixQuery: "?fit=crop&w=340&h=340",
    imageClass: computed('image', function () {
      if (get(this, 'image')) {
        return 'checkin-upload__drop-zone--has-image';
      }
    }),

    uploadingClass: computed('isUploading', function () {
      if (get(this, 'isUploading')) {
        return 'checkin-upload__drop-zone--uploading';
      }
    }),

    uploadFolder: "checkin-images",

    image: computed('value', 'isUploading', function () {
      var uploadedImg = get(this, 'value');
      var isUploading = get(this, 'isUploading');

      if (uploadedImg && !isUploading) {
        return uploadedImg;
      } else {
        return false;
      }
    }),

    actions: {
      didChange: function didChange() {
        this.set('isUploading', true);
        this.set('progress', 0);
      },
      onProgress: function onProgress(e) {
        this.set('progress', e.percent);
      },
      onError: function onError() /*jqXHR, textStatus, errorThrown*/{
        alert('Unable to complete the upload. Please contact support@fitbasepro.com.');
        this.set('isUploading', false);
      },
      setUploadedUrl: function setUploadedUrl(url) {
        var _this = this;

        var urlPath = url.replace(/^.*amazonaws\.com/, '');
        this.get('update')(urlPath);
        this.get('save')().then(function () {
          _this.set('isUploading', false);
        });
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('update')) {
        throw new Error('You must provide an `update` action to `{{' + this.templateName + '}}`.');
      }
      this.get('update')(this.get('value'));
    }
  });
});