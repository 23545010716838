define('fitbase/components/checkins-list/component', ['exports', 'fitbase/utils/field-units'], function (exports, _fieldUnits) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var htmlSafe = Ember.String.htmlSafe;
  var filter = Ember.computed.filter;


  var unitFields = _fieldUnits.default.lookupList('inputType');

  exports.default = Component.extend({
    isShowingIncomplete: false,
    classNames: 'checkins-list',
    classNameBindings: ['isShowingIncomplete:checkins-list--show-incomplete'],
    fieldsSorted: computed('fields.[]', function () {
      if (get(this, 'fields')) {
        return get(this, 'fields').sortBy('sequence', 'id');
      }
    }),

    numberFields: filter('fieldsSorted.[]', function (f) {
      return unitFields[get(f, 'unit')] === "number";
    }),

    hasNoteFields: computed('fields.[]', function () {
      if (get(this, 'fields')) {
        return get(this, 'fields').any(function (item) {
          return ["note", "text"].indexOf(unitFields[get(item, 'unit')]) >= 0;
        });
      } else {
        return false;
      }
    }),

    columnLimit: false,
    colspan: computed('columnLimit', 'numberfields.[]', function () {
      var limit = get(this, 'columnLimit');
      var numFieldLen = get(this, 'numberFields.length');
      var count = numFieldLen > limit ? limit : numFieldLen;
      var colCount = count ? count + 1 : 2;
      return htmlSafe(colCount);
    }),

    actions: {
      toggleShowingIncomplete: function toggleShowingIncomplete() {
        this.toggleProperty('isShowingIncomplete');
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      var limit = void 0;
      var width = window.innerWidth;
      if (width < 413) limit = 3;
      if (width > 414) limit = 4;
      if (width > 672) limit = 6;
      if (width > 960) limit = false;
      this.set('columnLimit', limit);
    }
  });
});