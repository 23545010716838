define('fitbase/application/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var alias = Ember.computed.alias;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    session: service(),
    currentUser: alias('session.currentUser'),
    flashMessages: service()
  });
});