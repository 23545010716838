define('fitbase/application/adapter', ['exports', 'ember-data/adapters/json-api', 'ember-simple-auth/mixins/data-adapter-mixin', 'fitbase/config/environment', 'ember-data-url-templates'], function (exports, _jsonApi, _dataAdapterMixin, _environment, _emberDataUrlTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;


  var urlSegments = {
    namespace: function namespace() {
      return _environment.default.DS.namespace;
    }
  };

  exports.default = _jsonApi.default.extend(_emberDataUrlTemplates.default, _dataAdapterMixin.default, {
    host: _environment.default.DS.host,
    authorizer: 'authorizer:application',
    urlTemplate: '{+host}/{+namespace}/{pathForType}{/id}{?query*}',
    urlSegments: urlSegments,

    handleResponse: function handleResponse(status, headers, payload) {
      if (get(this, 'session.isAuthenticated') && payload) {
        var error_status = get(payload, 'errors.0.status');
        if (status === 401 || error_status === 401) {
          get(this, 'session').invalidate();
        }
      }
      return this._super.apply(this, arguments);
    }
  });
});