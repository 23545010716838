define('fitbase/coach/clients/client/edit/checkin-form/import/route', ['exports', 'fitbase/mixins/uses-modals'], function (exports, _usesModals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend(_usesModals.default, {
    model: function model() {
      return this.store.findAll('checkin-template');
    },
    _clearSelected: function _clearSelected() {
      this.controller.set('selectedTemplate', null);
    },
    _select: function _select(template) {
      this.controller.set('selectedTemplate', template);
    },


    actions: {
      selectTemplate: function selectTemplate(template) {
        this._select(template);
      },
      viewAll: function viewAll() {
        this._clearSelected();
      },
      importTemplate: function importTemplate(template) {
        var _this = this;

        var fields = [];
        var checkinForm = this.modelFor('coach.clients.client.edit.checkin-form');
        var fieldsMap = template.get('fields');
        Object.keys(fieldsMap).forEach(function (seq) {
          var attrs = {
            text: fieldsMap[seq].text,
            unit: fieldsMap[seq].unit,
            sequence: fieldsMap[seq].sequence,
            checkinForm: checkinForm,
            _imported: true
          };
          var field = _this.store.createRecord('checkin-field', attrs).save();
          fields.push(field);
        });
        RSVP.all(fields).then(function () {
          checkinForm.set('shouldSave', true);
          _this.send('closeModal');
        });
      },
      deleteTemplate: function deleteTemplate(template) {
        template.destroyRecord();
        this._clearSelected();
      },
      closeModal: function closeModal() {
        this._clearSelected();
        this.replaceWith('coach.clients.client.edit.checkin-form');
      }
    }
  });
});