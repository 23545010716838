define('fitbase/coach/clients/client/edit/checkin-form/create-template/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    fields: computed('model.fields', function () {
      var fieldsMap = get(this, 'model.fields');

      return Object.keys(fieldsMap).map(function (k) {
        return fieldsMap[k];
      }).sort(function (a, b) {
        if (a.sequence == b.sequence) return 0;
        if (a.sequence < b.sequence) return -1;
        if (a.sequence > b.sequence) return 1;
      });
    })
  });
});