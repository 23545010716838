define('fitbase/helpers/client-initials', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.clientInitials = clientInitials;
  var buildHelper = Ember.Helper.helper;
  function clientInitials(params /*, hash*/) {
    if (params && params[0]) {
      var names = params[0].split(' ');
      var first = names[0];
      var last = names[1];
      return first[0] + (last ? last[0] : '');
    } else {
      return '';
    }
  }

  exports.default = buildHelper(clientInitials);
});