define('fitbase/router', ['exports', 'fitbase/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberRouter = Ember.Router;
  var scheduleOnce = Ember.run.scheduleOnce;
  var service = Ember.inject.service;
  var $ = Ember.$;


  var Router = EmberRouter.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    intercom: service(),
    session: service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
      this._classifyPage();
      this._scrollTop();
    },
    _scrollTop: function _scrollTop() {
      if (!this.get('noResetScroll')) {
        window.scrollTo(0, 0);
      }
    },
    _trackPage: function _trackPage() {
      var _this = this;

      scheduleOnce('afterRender', this, function () {
        //const page = this.get('url');
        //const title = this.getWithDefault('currentRouteName', 'unknown');
        _this.get('intercom').update();
      });
    },
    _classifyPage: function _classifyPage() {
      var _this2 = this;

      scheduleOnce('afterRender', this, function () {
        var path = _this2.get('url').replace(/\//g, '_').replace('_', '');
        var route = _this2.getWithDefault('currentRouteName', 'unknown');
        $('body > .ember-view').attr('route', route).attr('path', path);
      });
    }
  });

  Router.map(function () {
    this.route('auth.register', { path: 'signup' });
    this.route('auth.login', { path: 'login' });
    this.route('auth.logout', { path: 'logout' });

    this.route('coach', function () {
      this.route('settings', function () {
        this.route('profile');
        this.route('security');
      });
      this.route('clients', function () {
        this.route('new');

        this.route('client', { path: ':id' }, function () {
          this.route('checkins', function () {
            this.route('pictures');
          });
          this.route('edit', function () {
            this.route('checkin-form', function () {
              this.route('create-template');
              this.route('import');
            });
          });
        });
      });
    });
    this.route('checkin-link', { path: 'checkin-link/:id/:link_id' });
    this.route('checkin', { path: 'checkin/:id' });

    this.route('not-found', { path: '/*path' });
  });

  exports.default = Router;
});