define('fitbase/checkin-link/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var service = Ember.inject.service;
  var Route = Ember.Route;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = Route.extend({
    session: service(),

    routeIfAlreadyAuthenticated: computed(function () {
      var start_path = get(this, 'session.data.start_path');
      if (start_path) return start_path;

      return '/' + get(this, 'session.subject').join('/');
    }),

    model: function model(params) {
      var id = params.id,
          link_id = params.link_id;

      return { id: id, link_id: link_id };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      if (get(this, 'session.isAuthenticated')) {
        var _get = get(this, 'session.subject'),
            _get2 = _slicedToArray(_get, 2),
            subject = _get2[0],
            id = _get2[1];

        if (subject === 'checkin' && id === model.id) {
          this.transitionTo(get(this, 'routeIfAlreadyAuthenticated'));
        } else {
          this._doAuth(model).then(function () {
            _this.transitionTo(get(_this, 'routeIfAlreadyAuthenticated'));
          });
        }
      } else {
        this._doAuth(model);
      }
    },
    _doAuth: function _doAuth(model) {
      var id = model.id,
          link_id = model.link_id;

      get(this, 'session').unidentify();
      get(this, 'session').set('data.start_path', '/checkin/' + id);

      return get(this, 'session').authenticate('authenticator:checkin', id, link_id).catch(function (reason) {
        console.error(reason);
        alert('Check-in link seems to be invalid. Try again, or contact support, support@fitbasepro.com');
      });
    }
  });
});