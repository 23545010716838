define('fitbase/components/header-nav/checkin/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: "header",
    classNames: ['checkin-head'],
    session: service(),
    intercom: service(),

    didInsertElement: function didInsertElement() {
      this.get("intercom").stop();
    },
    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);
      var intercom = this.get('intercom');
      this.get('session.currentSubject').then(function (subject) {
        var subjectType = subject.constructor.modelName;
        var namespace = '';
        if (subjectType == "checkin") {
          namespace = 'client.';
        }

        var name = subject.get(namespace + 'name');
        var email = subject.get(namespace + 'email');
        var hash = _this.get('session.claims.ic');
        var user_id = _this.get('session.claims.sub');

        intercom.set('user.name', name);
        intercom.set('user.email', email);
        intercom.set('user.user_hash', hash);
        intercom.set('user.user_id', user_id);

        var attrs = {
          email: email,
          name: name,
          user_id: user_id,
          user_hash: hash
        };
        intercom.update(attrs);
        intercom.start();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.get('intercom').stop();
    }
  });
});