define('fitbase/components/checkin-form-progress/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  var not = Ember.computed.not;
  var htmlSafe = Ember.String.htmlSafe;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['checkin-form__progress', 'checkin-form-progress'],
    classNameBindings: ['filled'],
    percent: 0,
    progress: computed('percent', function () {
      return htmlSafe(parseFloat(get(this, 'percent')).toFixed(2) + '%');
    }),
    filled: computed('percent', function () {
      return get(this, 'percent') === 100 ? 'checkin-form-progress--filled' : '';
    }),
    isSummary: or('checkin.{isReviewing,completedAndSaved}'),
    isVisible: not('isSummary')
  });
});