define('fitbase/coach/clients/client/edit/index/route', ['exports', 'fitbase/mixins/authenticated-trainer-route-mixin', 'ember-buffered-proxy/proxy'], function (exports, _authenticatedTrainerRouteMixin, _proxy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_authenticatedTrainerRouteMixin.default, {

    model: function model() {
      var params = this.paramsFor('coach.clients.client');
      var client = this.store.peekRecord('client', params.id);

      return _proxy.default.create({ content: client });
    },

    deactivate: function deactivate() {
      this.set('showValidations', false);
      this.controller.set('confirmingArchive', false);
    },
    _routeModel: function _routeModel() {
      return this.modelFor(this.routeName);
    },


    actions: {
      save: function save() {
        var buffer = this._routeModel();
        var client = buffer.get('content');

        buffer.applyChanges();
        client.save().catch(function () {});
      },
      cancel: function cancel() {
        this.transitionTo('coach.clients.client');
      },
      confirmArchive: function confirmArchive() {
        var client = this._routeModel().get('content');
        client.set('state', 'archived');
        client.save();
        this.controller.set('confirmingArchive', false);
      },
      unarchive: function unarchive() {
        var client = this._routeModel().get('content');
        client.set('state', 'paused');
        client.save();
      },
      willTransition: function willTransition(transition) {
        var buffer = this._routeModel();
        var client = buffer.get('content');

        if (client.get('hasDirtyAttributes') || buffer.get('hasBufferedChanges')) {
          if (confirm("You have unsaved changes. If you leave, they will be lost.")) {
            client.rollbackAttributes();
          } else {
            transition.abort();
          }
        }
      }
    }
  });
});