define('fitbase/components/auth-error/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var notEmpty = Ember.computed.notEmpty;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['auth-error'],
    classNameBindings: ['hasError:auth-error--visible'],
    hasError: notEmpty('message')
  });
});