define('fitbase/helpers/moment-weeks-ago', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.momentWeeksAgo = momentWeeksAgo;
  var buildHelper = Ember.Helper.helper;
  function momentWeeksAgo(params) {
    var dateInput = (0, _moment.default)(params[0]).hours(0).minutes(0).seconds(0).milliseconds(0);
    var today = void 0;
    var afterPrefix = '';
    var afterSuffix = '';
    if (params[1]) {
      today = (0, _moment.default)(params[1]).hours(0).minutes(0).seconds(0).milliseconds(0);
      afterSuffix = ' later';
    } else {
      today = (0, _moment.default)().hours(0).minutes(0).seconds(0).milliseconds(0);
      afterPrefix = 'in ';
    }
    var yesterday = today.clone().subtract(1, 'days');
    var yesterweek = today.clone().subtract(14, 'days');
    var nextweek = today.clone().add(6, 'days');

    if (dateInput.isBefore(yesterweek)) {
      var weeks = today.diff(dateInput, 'weeks');
      return weeks + ' week' + (weeks > 1 ? 's' : '') + ' ago';
    }

    if (dateInput.isBefore(yesterday)) {
      return dateInput.from(today);
    }

    if (dateInput.isSame(yesterday)) {
      return 'yesterday';
    }

    if (dateInput.isSame(today)) {
      return 'today';
    }

    if (dateInput.isAfter(nextweek)) {
      var _weeks = dateInput.diff(today, 'weeks');
      return afterPrefix + (_weeks + ' week') + (_weeks > 1 ? 's' : '') + afterSuffix;
    }
  }

  exports.default = buildHelper(momentWeeksAgo);
});