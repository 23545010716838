define('fitbase/components/pics-column/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var map = Ember.computed.map;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var isEmpty = Ember.isEmpty;
  exports.default = Component.extend({
    classNames: ['pics-column'],
    checkins: null,
    checkinOptions: map('checkins', function (checkin) {
      var dueAt = get(checkin, 'dueAt');
      return {
        value: checkin,
        label: (0, _moment.default)(dueAt).format('MMM DD, YYYY')
      };
    }),
    currentField: null,
    selectedCheckin: null,
    currentCheckin: computed('selectedCheckin', 'checkins.[]', function () {
      var selectedCheckin = get(this, 'selectedCheckin');

      if (!isEmpty(selectedCheckin)) {
        return selectedCheckin;
      } else {
        return get(this, 'checkins.' + get(this, 'default'));
      }
    }),

    indexOfCurrentCheckin: computed('checkins.[]', 'currentCheckin', function () {
      return get(this, 'checkins').indexOf(get(this, 'currentCheckin'));
    }),

    reelTop: computed('imageCheckinValues.length', 'currentField', 'indexOfCurrentCheckin', function () {
      return get(this, 'indexOfCurrentCheckin') / get(this, 'imageCheckinValues.length') * -100;
    }),

    hasPreviousCheckin: computed('indexOfCurrentCheckin', function () {
      return get(this, 'indexOfCurrentCheckin') > 0;
    }),

    hasNextCheckin: computed('imageCheckinValues.length', 'indexOfCurrentCheckin', function () {
      var index = get(this, 'indexOfCurrentCheckin');
      var length = get(this, 'checkins.length');
      return length > index + 1;
    }),

    indexDots: computed('imageCheckinValues.[]', function () {
      return new Array(get(this, 'imageCheckinValues.length'));
    }),

    actions: {
      previousCheckin: function previousCheckin() {
        if (!get(this, 'hasPreviousCheckin')) {
          return false;
        }
        this._sequenceCheckin(-1);
      },
      nextCheckin: function nextCheckin() {
        if (!get(this, 'hasNextCheckin')) {
          return false;
        }
        this._sequenceCheckin(1);
      },
      isCurrentIndex: function isCurrentIndex(i, currentIndex) {
        return i == currentIndex;
      }
    },

    _sequenceCheckin: function _sequenceCheckin(indexSeq) {
      var move = indexSeq > 0 ? 1 : -1;
      var newIndex = get(this, 'indexOfCurrentCheckin') + move;
      var newCheckin = get(this, 'checkins').objectAt(newIndex);
      this.set('selectedCheckin', newCheckin);
    }
  });
});