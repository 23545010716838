define('fitbase/checkin-value/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    value: attr('string'),
    delta: attr('delta'),
    insertedAt: attr('date'),

    // relationships
    checkin: belongsTo('checkin'),
    checkinField: belongsTo('checkin-field')
  });
});