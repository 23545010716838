define('fitbase/coach/settings/profile/controller', ['exports', 'fitbase/utils/user-validations', 'ember-cp-validations'], function (exports, _userValidations, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.name': _userValidations.fullname,
    'model.email': _userValidations.email
  });

  exports.default = Controller.extend(Validations, {

    showValidations: false,

    hasChanges: computed('model.{hasBufferedChanges,hasDirtyAttributes}', function () {
      return this.get('model.hasBufferedChanges') || this.get('model.hasDirtyAttributes');
    }),

    namePlaceholder: computed('model.name', function () {
      if (this.get('model.name')) {
        return 'Client emails will show from "' + this.get('model.name') + ' via Fitspace Pro"';
      } else {
        return 'Client emails will show from "<Name> via Fitspace Pro"';
      }
    }),

    actions: {
      save: function save() {
        if (this.get('validations.isValid')) {
          return true;
        } else {
          this.set('showValidations', true);
        }
      }
    }
  });
});