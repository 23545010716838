define('fitbase/coach/clients/client/route', ['exports', 'fitbase/mixins/authenticated-trainer-route-mixin', 'ember-data'], function (exports, _authenticatedTrainerRouteMixin, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_authenticatedTrainerRouteMixin.default, {
    model: function model(params) {
      return this.store.findRecord('client', params.id);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.setProperties({
        clients: this.modelFor('coach.clients')
      });
    },


    actions: {
      error: function error(reason) {
        console.log(reason);
        if (reason instanceof _emberData.default.AdapterError && reason.errors) {
          console.log(reason.errors[0].detail);
          if (reason.errors[0].status && reason.errors[0].status == 404) {
            this.transitionTo('clients.index');
          }
        }
      }
    }
  });
});