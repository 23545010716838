define('fitbase/components/auth-registration/component', ['exports', 'fitbase/utils/user-validations', 'ember-cp-validations'], function (exports, _userValidations, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;


  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.email': _userValidations.email,
    'model.password': _userValidations.password,
    'model.fullname': _userValidations.fullname
  });

  exports.default = Component.extend(Validations, {
    classNames: ['auth-registration']
  });
});