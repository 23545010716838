define('fitbase/checkin/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var alias = Ember.computed.alias;
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var isEmpty = Ember.isEmpty;
  exports.default = Controller.extend({
    session: service(),
    checkin: alias('model'),
    client: alias('model.checkinForm.client'),
    name: alias('client.name'),
    checkinValues: alias('checkin.checkinValues'),
    checkinForm: alias('checkin.checkinForm'),
    checkinFields: alias('checkinForm.checkinFields'),
    fieldsCount: computed('checkinFields.[]', function () {
      return this.get('checkinFields.length');
    }),
    subject: alias('session.subjectType'),
    percentFilled: computed('checkinFields', 'checkinValues.@each.value', function () {
      var saved = this.get('checkinValues').filter(function (cv) {
        return !isEmpty(cv.get('value'));
      }).length;
      var count = this.get('fieldsCount');
      var percent = saved / count * 100;
      return percent;
    })
  });
});