define('fitbase/initializers/ember-hammertime', ['exports', 'ember-hammertime/components/component'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'ember-hammertime',
    initialize: function initialize() {}
  };
});