define('fitbase/coach/settings/profile/route', ['exports', 'fitbase/mixins/authenticated-trainer-route-mixin', 'ember-buffered-proxy/proxy'], function (exports, _authenticatedTrainerRouteMixin, _proxy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_authenticatedTrainerRouteMixin.default, {
    model: function model() {
      var u = this.modelFor('coach.settings');
      return _proxy.default.create({ content: u });
    },

    deactivate: function deactivate() {
      var buffer = this._routeModel();
      if (buffer.get('hasDirtyAttributes')) {
        buffer.get('content').rollbackAttributes();
      }
      buffer.discardChanges();
    },
    _routeModel: function _routeModel() {
      return this.modelFor(this.routeName);
    },


    actions: {
      save: function save() {
        var buffer = this._routeModel();
        buffer.applyChanges();
        var model = buffer.get('content');
        model.save().catch(function (reason) {
          console.error(reason);
          alert('Something went wrong. Please try again later.');
        });
      },
      cancel: function cancel() {
        this.transitionTo('coach.settings.index');
      }
    }

  });
});