define('fitbase/coach/settings/security/route', ['exports', 'fitbase/mixins/authenticated-trainer-route-mixin', 'ember-buffered-proxy/proxy'], function (exports, _authenticatedTrainerRouteMixin, _proxy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var Route = Ember.Route;
  exports.default = Route.extend(_authenticatedTrainerRouteMixin.default, {
    model: function model() {
      var u = this.modelFor('coach.settings');
      return _proxy.default.create({ content: u });
    },

    deactivate: function deactivate() {
      var buffer = this._routeModel();
      if (buffer.get('hasDirtyAttributes')) {
        buffer.get('content').rollbackAttributes();
      }
      buffer.discardChanges();
    },
    _routeModel: function _routeModel() {
      return this.modelFor(this.routeName);
    },


    actions: {
      save: function save() {
        var buffer = this._routeModel();
        buffer.applyChanges();
        var model = buffer.get('content');
        model.save().then(function () {
          model.setProperties({
            currentPassword: '',
            password: '',
            confirmPassword: ''
          });
          alert('Your password was successfully changed');
        }).catch(function (reason) {
          if (get(reason, 'errors.0.status') == 403) {
            alert('Looks like your "Old password" was incorrect');
            model.setProperties({
              currentPassword: '',
              password: '',
              confirmPassword: ''
            });
          }
        });
      },
      cancel: function cancel() {
        this.transitionTo('coach.settings.index');
      }
    }

  });
});