define('fitbase/components/checkin-field-number/component', ['exports', 'ember-gestures/mixins/recognizers'], function (exports, _recognizers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_recognizers.default, {
    tagName: ''
  });
});