define('fitbase/mixins/uses-modals', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    activate: function activate() {
      $('html').toggleClass('modal-active', true);
    },
    deactivate: function deactivate() {
      $('html').toggleClass('modal-active', false);
    }
  });
});