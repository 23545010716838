define('fitbase/coach/route', ['exports', 'fitbase/mixins/authenticated-trainer-route-mixin'], function (exports, _authenticatedTrainerRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var inject = Ember.inject.service;
  exports.default = Route.extend(_authenticatedTrainerRouteMixin.default, {
    phoenixSocket: inject(),
    activate: function activate() {
      this.phoenixSocket.connect();
    }
  });
});