define('fitbase/mixins/authenticated-route-mixin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create(_authenticatedRouteMixin.default, {
    authenticationRoute: 'auth.login'
  });
});