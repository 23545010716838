define('fitbase/components/field-schema/component', ['exports', 'fitbase/utils/field-units', 'ember-click-outside/mixin'], function (exports, _fieldUnits, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Component = Ember.Component;
  var get = Ember.get;
  var set = Ember.set;
  var setProperties = Ember.setProperties;
  var next = Ember.run.next;
  var later = Ember.run.later;
  var not = Ember.computed.not;
  var equal = Ember.computed.equal;
  var alias = Ember.computed.alias;
  exports.default = Component.extend(_mixin.default, {
    tagName: 'div',
    classNames: ['field-schema'],
    classNameBindings: ['isImage:field-schema--image', 'isInvalid:field-schema--invalid', 'isFocused:field-schema--focused'],
    schema: null,
    label: alias('schema.text'),
    labelPlaceholder: null,
    help: alias('schema.help'),
    helpPlaceholder: null,
    unit: alias('schema.unit'),
    unitOptions: _fieldUnits.default.selectOptions('label'),
    isValid: alias('schema.isValid'),
    isNew: alias('schema.isNew'),
    isInvalid: equal('isValid', false),
    isVisible: not('field.isDeleted'),
    isFocused: false,
    isPromptingRemove: false,
    skipBlur: false,

    save: function save() {},
    // pass in route-action
    remove: function remove() {},
    // pass in from parent context

    actions: {
      archive: function archive() {
        alert('Archive Error. Please contact support@fitspacepro.com');
      },
      promptRemove: function promptRemove() {
        if (this.isNew) {
          return this.send('confirmRemove');
        }
        return this.set('isPromptingRemove', true);
      },
      abortRemove: function abortRemove() {
        this.set('isPromptingRemove', false);
      },
      confirmRemove: function confirmRemove() {
        var _this = this;

        this.$('.field-schema__remove').remove();
        this.$().css('overflow', 'hidden').animate({ opacity: 0 }, 200).animate({ height: 0, marginBottom: 0 }, 100, function () {
          _this.set('isPromptingRemove', false);
          later(function () {
            _this.remove(_this.get('schema'));
          }, 200);
        });
      },
      enter: function enter() {
        this.clearFocused();
        get(this, 'enter')();
      }
    },

    _skipBlur: function _skipBlur() {
      set(this, 'skipBlur', true);
    },
    click: function click() {
      this.clearFocused();
      setProperties(this, { skipBlur: false, isFocused: true });
    },
    clickOutside: function clickOutside() {
      set(this, 'isFocused', false);
    },
    mouseDown: function mouseDown(e) {
      if (e.currentTarget === this.element) {
        set(this, "skipBlur", true);
      }
    },
    clearFocused: function clearFocused() {
      $('.field-schema.field-schema--focused').not(this.element).trigger('blur');
    },
    focusIn: function focusIn() {
      this.clearFocused();
      set(this, 'isFocused', true);
    },
    focusOut: function focusOut(e) {
      var noRelatedTarget = !this.$(this.element).find(e.relatedTarget).length;
      var notSkipBlur = !get(this, 'skipBlur');
      var wasFocused = get(this, 'isFocused');

      if (noRelatedTarget && notSkipBlur) {
        set(this, 'isFocused', false);
        set(this, 'skipBlur', false);

        if (wasFocused) {
          get(this, "save")(get(this, "schema"));
        }
      }
    },
    keyUpSelect: function keyUpSelect(e) {
      if (e.keyCode === 13) {
        this.get('parentView.enter')();
      }
    },
    didInsertElement: function didInsertElement() {
      var isNew = get(this, 'isNew');
      var isImported = get(this, 'schema._imported');
      if (isNew && !isImported) {
        this.$('textarea').focus();
      }
      next(this, this.addClickOutsideListener);
    },
    willDestroyElement: function willDestroyElement() {
      this.removeClickOutsideListener();
    }
  });
});