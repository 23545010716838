define('fitbase/coach/clients/client/edit/checkin-form/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Route.extend({
    model: function model() {
      var client = this.modelFor('coach.clients.client');
      return get(client, 'checkinForm').reload().then(function (form) {
        return form;
      });
    },
    afterModel: function afterModel(checkinForm) {
      if (!checkinForm) {
        this.transitionTo('coach.clients.client.index');
      }
    },
    routeModel: function routeModel() {
      return this.modelFor(this.routeName);
    },
    _clearUnsavedCheckinForm: function _clearUnsavedCheckinForm(checkinForm, client) {
      if (get(checkinForm, 'isNew')) {
        checkinForm.destroyRecord();
        set(client, 'checkinForm', null);
        return client.save();
      } else {
        return checkinForm.rollbackAttributes();
      }
    },


    actions: {
      save: function save() {
        var form = this.routeModel();
        form.get('checkinFields').filterBy('isNew', true).invoke('destroyRecord');
        form.save().then(function () {
          form.set('shouldSave', false);
          form.get('client').then(function (client) {
            client.reload();
          });
        });
      },
      saveField: function saveField(field) {
        if (get(field, 'hasDirtyAttributes')) {
          return field.save().catch(function (e) {
            console.warn('failed to save field', e);
          });
        }
      },
      done: function done() {
        var _this = this;

        this.routeModel().get('client').then(function (client) {
          _this.transitionTo('coach.clients.client', client);
        });
      },
      addCheckinField: function addCheckinField(sequence) {
        var checkinForm = this.routeModel();
        checkinForm.set('shouldSave', true);

        return get(this, 'store').createRecord('checkin-field', { checkinForm: checkinForm, sequence: sequence });
      },
      createTemplate: function createTemplate() {
        this.replaceWith('coach.clients.client.edit.checkin-form.create-template');
      },
      showTemplates: function showTemplates() {
        this.replaceWith('coach.clients.client.edit.checkin-form.import');
      },
      cancel: function cancel() {
        var _this2 = this;

        var checkinForm = this.routeModel();
        checkinForm.get('client').then(function (client) {
          _this2._clearUnsavedCheckinForm(checkinForm, client);
          return _this2.transitionTo('coach.clients.client', client);
        });
      },
      clearNextAt: function clearNextAt() {
        this.routeModel().set('nextAt', null);
      },
      clearEndAt: function clearEndAt() {
        var client = this.modelFor('coach.clients.client');
        client.set('icsDtend', null);
        if (client.get('hasDirtyAttributes')) {
          return client.save();
        }
      },
      selectedEndAt: function selectedEndAt(value) {
        var client = this.modelFor('coach.clients.client');
        client.set('icsDtend', value);
        if (client.get('hasDirtyAttributes')) {
          return client.save();
        }
      },
      clearFrequency: function clearFrequency() {
        this.routeModel().set('frequency', null);
      },
      removeCheckinField: function removeCheckinField(field) {
        this.routeModel().set('shouldSave', true);
        field.destroyRecord();
      },
      willTransition: function willTransition(transition) {
        var checkinForm = this.routeModel();
        var hasChanges = get(checkinForm, 'hasDirtyAttributes');
        var confirmAbortMessage = 'Are you sure you want to leave this page and abandon your changes?';
        var isNotChildRoute = transition.targetName.indexOf(this.get('routeName'));
        if (isNotChildRoute) {
          if (hasChanges && !confirm(confirmAbortMessage)) {
            transition.abort();
          } else {
            this.routeModel().rollbackAttributes();
            return true;
          }
        }
      }
    }

  });
});