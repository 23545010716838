define('fitbase/components/field-schema-sortable/component', ['exports', 'fitbase/utils/field-units'], function (exports, _fieldUnits) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var alias = computed.alias;
  exports.default = Component.extend({
    classNames: ['field-schema', 'field-schema--sortable'],
    attributeBindings: ['data-num'],
    label: alias('schema.text'),
    unit: alias('schema.unit'),
    unitLabel: computed('unit', function () {
      return _fieldUnits.default.lookupList('label')[get(this, 'unit')];
    }),
    "data-num": computed('index', function () {
      return get(this, 'index') + 1;
    })
  });
});