define('fitbase/components/checkin-history-row/component', ['exports', 'ember-click-outside/mixin'], function (exports, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var sort = Ember.computed.sort;
  var filterBy = Ember.computed.filterBy;
  var filter = Ember.computed.filter;
  var not = Ember.computed.not;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var next = Ember.run.next;
  exports.default = Component.extend(_mixin.default, {
    init: function init() {
      this.sort = ["sequence:asc"];
      this._super.apply(this, arguments);
    },

    tagName: 'tbody',
    classNames: ['checkin-history__row'],
    classNameBindings: ['isShowingNotes', 'isIncomplete:checkin-history__row--incomplete', 'hasNotes:checkin-history__row--has-notes'],
    numbersColspan: computed('colspan', function () {
      return get(this, 'colspan') - 1;
    }),
    checkinDate: alias('checkin.dueAt'),
    checkinFieldValuesSorted: sort('checkinFieldValues', 'sort'),
    checkinNumbers: filterBy('checkinFieldValuesSorted', 'inputType', 'number'),
    checkinNotes: filter('checkinFieldValuesSorted', function (f) {
      return ["text", "note"].indexOf(get(f, 'inputType')) >= 0;
    }),
    hasNotes: computed('checkinNotes.length', function () {
      return !!get(this, 'checkinNotes.length');
    }),
    isShowingNotes: false,
    isIncomplete: not('checkin.completedAt'),

    click: function click() {
      if (!get(this, 'hasNotes')) {
        return;
      }
      this.toggleProperty("isShowingNotes");
    },
    clickOutside: function clickOutside() {
      this.set('isShowingNotes', false);
    },
    didInsertElement: function didInsertElement() {
      next(this, this.addClickOutsideListener);
    },
    willDestroyElement: function willDestroyElement() {
      this.removeClickOutsideListener();
    }
  });
});