define('fitbase/components/checkin-latest/component', ['exports', 'fitbase/utils/field-units', 'moment'], function (exports, _fieldUnits, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var empty = Ember.computed.empty;
  var sort = Ember.computed.sort;
  var filter = Ember.computed.filter;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;


  var unitFields = _fieldUnits.default.lookupList('inputType');

  exports.default = Component.extend({
    init: function init() {
      this.sort = ["sequence:asc"];
      this._super.apply(this, arguments);
    },

    tagName: 'section',
    classNames: ['checkin-latest'],
    client: null,
    checkin: null,
    completedDate: computed('checkin.completedAt', function () {
      var date = (0, _moment.default)(get(this, 'checkin.completedAt'));
      return date.format("dddd - MMMM D, Y @ ha ");
    }),
    checkinNeedsSetup: empty('client.checkinForm.id'),

    numbersSorted: sort('checkinNumbers', 'sort'),
    checkinNumbers: filter('checkin.checkinFieldValues', function (f) {
      return unitFields[get(f, 'unit')] === "number";
    }),
    numbersSortedSlice: computed('numbersSorted', function () {
      return get(this, 'numbersSorted').slice(1);
    }),
    numberSlicePage: 1,
    sliceMaxLength: 6,
    numberSlicePages: computed('sliceMaxLength', 'checkinNumbers.[]', function () {
      return Math.ceil((get(this, 'checkinNumbers.length') - 1) / get(this, 'sliceMaxLength')) || 1;
    }),
    numberSliceIndex: computed('numberSlicePage', 'numberSlicePages', 'checkinNumbers.length', 'sliceMaxLength', function () {
      var maxLength = get(this, 'sliceMaxLength'),
          page = get(this, 'numberSlicePage'),
          pages = get(this, 'numberSlicePages'),
          isTail = page === pages;
      if (pages > 1) {
        if (isTail) {
          return get(this, 'checkinNumbers.length') - 1 - maxLength;
        } else {
          return (page - 1) * maxLength;
        }
      } else {
        return 0;
      }
    }),
    isInitialNumbers: computed('numberSlicePage', 'numberSlicePages', function () {
      return get(this, 'numberSlicePage') === 1;
    }),
    isTailNumbers: computed('numberSlicePage', 'numberSlicePages', function () {
      return get(this, 'numberSlicePage') === get(this, 'numberSlicePages');
    }),
    sliceLeft: computed('numberSliceIndex', function () {
      return get(this, 'numberSliceIndex') * 88;
    }),

    notesSorted: sort('checkinNotes', 'sort'),
    checkinNotes: filter('checkin.checkinFieldValues', function (f) {
      return ["text", "note"].indexOf(unitFields[get(f, 'unit')]) >= 0;
    }),

    checkinSectionTitle: computed('checkinNeedsSetup', 'client.firstName', 'checkin.{completedAt,isFulfilled}', function () {
      if (get(this, 'checkinNeedsSetup')) {
        return 'You need to setup ' + get(this, 'client.firstName') + '\'s check-ins';
      }
      if (get(this, 'checkin.completedAt')) {
        return 'Latest check-in';
      }
      if (get(this, 'checkin.isFulfilled')) {
        return get(this, 'client.firstName') + ' hasn\'t checked in yet.';
      }
    }),

    actions: {
      createCheckinForm: function createCheckinForm() {
        this.get('createCheckinForm')();
      },
      nextNumberPage: function nextNumberPage() {
        if (get(this, 'numberSlicePage') <= get(this, 'numberSlicePages')) {
          this.incrementProperty('numberSlicePage');
        }
      },
      prevNumberPage: function prevNumberPage() {
        if (get(this, 'numberSlicePage') > 1) {
          this.decrementProperty('numberSlicePage');
        }
      }
    }
  });
});