define('fitbase/helpers/moment-ref-date', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.relativeDate = relativeDate;
  exports.momentRefDate = momentRefDate;
  var buildHelper = Ember.Helper.helper;
  function relativeDate(date) {
    var hideSuffix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    var dateMoment = (0, _moment.default)(date);
    var today = (0, _moment.default)().hours(0).minutes(0).seconds(0).milliseconds(0);
    var yesterday = today.clone().subtract(1, 'days');
    var tomorrow = today.clone().add(1, 'days');
    var dateInput = dateMoment.hours(0).minutes(0).seconds(0).milliseconds(0);

    switch (true) {
      case dateInput.isBefore(yesterday):
        return dateInput.from(today, hideSuffix);
      case dateInput.isSame(yesterday):
        return 'yesterday';
      case dateInput.isSame(today):
        return 'today';
      case dateInput.isSame(tomorrow):
        return 'tomorrow';
      case dateInput.isAfter(tomorrow):
        return dateInput.from(today, hideSuffix);
      default:
        console.error('Unable to calculate relative date for', date);
        return '';
    }
  }

  function momentRefDate(params, _ref) {
    var _ref$hideSuffix = _ref.hideSuffix,
        hideSuffix = _ref$hideSuffix === undefined ? false : _ref$hideSuffix;

    return relativeDate(params[0], hideSuffix);
  }

  exports.default = buildHelper(momentRefDate);
});