define('fitbase/lib/assert-closure', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var _console;

    (_console = console).error.apply(_console, ['Please pass a method to this property to override this default assertion'].concat(Array.prototype.slice.call(arguments)));
  };
});