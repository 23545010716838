define('fitbase/components/clients-nav/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var getWithDefault = Ember.getWithDefault;
  var Component = Ember.Component;
  var notEmpty = Ember.computed.notEmpty;
  var filterBy = Ember.computed.filterBy;
  var filter = Ember.computed.filter;
  var sort = Ember.computed.sort;
  var alias = Ember.computed.alias;
  exports.default = Component.extend({
    init: function init() {
      this.clientSorts = ["name"];
      this._super.apply(this, arguments);
    },

    session: service(),
    tagName: 'nav',
    classNames: ['clients-nav', 'column', 'column--left'],
    classNameBindings: ['isIndex', 'isFiltering', 'filteringBy'],
    filteringBy: computed('session.filteringClientsBy', {
      get: function get() {
        return getWithDefault(this, 'session.filteringClientsBy', "");
      },
      set: function set() {
        var _arguments = Array.prototype.slice.call(arguments),
            value = _arguments[1];

        this.set('session.filteringClientsBy', value);
        return value;
      }
    }),
    query: null,

    queriedBy: computed('query', 'allClients.length', function () {
      if (this.query) {
        return 'matching ' + this.query + '.';
      } else if (this.allClients.length === 0) {
        return 'yet. They\'ll show here after you add some.';
      } else {
        return '.';
      }
    }),

    matchQuery: function matchQuery(clients) {
      var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

      if (!query) {
        return clients;
      }
      query = query.toLowerCase();

      var filtered = clients.filter(function (client) {
        var nameMatch = client.get('name').toLowerCase().indexOf(query) >= 0;
        var emailMatch = client.get('email').toLowerCase().indexOf(query) >= 0;
        return nameMatch || emailMatch;
      });
      console.log(filtered);
      return filtered;
    },


    isFiltering: notEmpty('filteringBy'),

    clients: computed('filteringBy', 'query', function () {
      var filter = this.get('filteringBy');
      var prop = '';
      switch (filter) {
        case "unreplied":
          prop = 'unrepliedClients';
          break;
        case "late":
          prop = 'lateClients';
          break;
        case "ended":
          prop = 'endedClients';
          break;
        default:
          prop = 'activeClientsSorted';
          break;
      }
      var list = this.get(prop);
      return this.matchQuery(list, this.query);
    }),
    activeClientsSorted: sort('activeClients', 'clientSorts'),
    activeClients: filter('allClients.@each.{state,isNew}', function (client) {
      return client.get('state') !== "archived" && client.get('isNew') == false;
    }),
    activeClientsCount: alias('activeClients.length'),

    newClients: filterBy('activeClientsSorted', 'state', "new"),
    newClientsCount: alias('newClients.length'),

    endedClients: filterBy('activeClientsSorted', 'isEnded'),
    endedClientsCount: alias('endedClients.length'),

    unrepliedClients: filter('activeClientsSorted.@each.{state,hasUncoachedMessage}', function (client) {
      return client.get('state') == 'checked_in' || client.get('hasUncoachedMessage');
    }),
    unrepliedClientsCount: alias('unrepliedClients.length'),
    checkedInClientsCount: computed('activeClientsSorted.@each.{state}', function () {
      return this.get('activeClientsSorted').filterBy('state', 'checked_in').get('length');
    }),

    lateClients: filter('activeClientsSorted.@each.{state,stateUpdatedAt}', function (client) {
      var isOpen = client.get('state') == 'open_checkin';
      var updatedAt = client.get('stateUpdatedAt');
      var isLateAt = (0, _moment.default)(updatedAt).add(1, 'days');
      return (0, _moment.default)().isAfter(isLateAt) && isOpen;
    }),
    lateClientsCount: alias('lateClients.length'),

    archivedClients: filterBy('allClients', 'state', "archived"),
    archivedClientsCount: alias('archivedClients.length'),
    archivedClientsSorted: sort('archivedClients', 'clientSorts'),
    hidingArchived: true,

    actions: {
      toggleArchivedClients: function toggleArchivedClients() {
        this.toggleProperty('hidingArchived');
      },
      filterClients: function filterClients(filterBy) {
        var isFilteringBy = this.get('filteringBy');
        if (filterBy == isFilteringBy) {
          filterBy = "";
        }
        this.set("filteringBy", filterBy);
      }
    }
  });
});