define('fitbase/coach/clients/client/checkins/pictures/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var filterBy = Ember.computed.filterBy;
  var sort = Ember.computed.sort;
  var filter = Ember.computed.filter;
  var map = Ember.computed.map;
  var Controller = Ember.Controller;
  var get = Ember.get;
  var computed = Ember.computed;
  var isEmpty = Ember.isEmpty;
  exports.default = Controller.extend({
    init: function init() {
      this.sortCheckinBy = ['dueAt:asc'];
      this._super.apply(this, arguments);
    },

    completedCheckins: filterBy('model.checkins', 'completedAt'),
    sortedCheckins: sort('completedCheckins', 'sortCheckinBy'),

    checkins: computed('sortedCheckins', 'currentField', function () {
      var _this = this;

      return get(this, 'sortedCheckins').filter(function (checkin) {
        var checkinValue = get(checkin, 'checkinFieldValues').find(function (fv) {
          return get(fv, 'id').split('-')[1] == get(_this, 'currentField.id');
        });
        return !isEmpty(get(checkinValue, 'value'));
      });
    }),

    imageFields: filter('model.checkinFields', function (field) {
      return get(field, 'unit') == 'image';
    }),

    selectedField: null,

    fieldOptions: map('imageFields', function (field) {
      return {
        value: field,
        label: get(field, 'text')
      };
    }),

    currentField: computed('selectedField', 'imageCheckinValues.[]', function () {
      var selectedField = get(this, 'selectedField');

      if (!isEmpty(selectedField)) {
        return selectedField;
      } else {
        return get(this, 'imageFields.firstObject');
      }
    }),

    imageCheckinValues: computed('checkins', 'checkins.[]', 'currentField', function () {
      var _this2 = this;

      return get(this, 'checkins').map(function (checkin) {
        return get(checkin, 'checkinFieldValues').find(function (fv) {
          return get(fv, 'id').split('-')[1] == get(_this2, 'currentField.id');
        });
      });
    }),

    actions: {
      selectField: function selectField(field) {
        this.set('selectedField', field);
      }
    }
  });
});