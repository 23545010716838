define('fitbase/components/checkin-intro/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  var not = Ember.computed.not;
  var equal = Ember.computed.equal;
  var and = Ember.computed.and;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['checkin-intro'],
    isReviewing: or('checkin.{isReviewing,completedAndSaved}'),
    isNotReviewing: not('isReviewing'),
    isFilled: equal('percent', 100),
    isEditingAndFilled: and('isNotReviewing', 'isFilled'),

    title: computed('isReviewing', 'percent', function () {
      switch (true) {
        case get(this, 'isReviewing'):
          return "Great job";
        case get(this, 'isEditingAndFilled'):
          return "You answered them all";
        default:
          return "It's time to check in!";
      }
    }),
    instruction: computed('isReviewing', 'percent', function () {
      switch (true) {
        case get(this, 'isReviewing'):
          return "Here's a summary...";
        case get(this, 'isEditingAndFilled'):
          return "Click done to review your responses";
        default:
          return 'Fill out the form below...';
      }
    })
  });
});