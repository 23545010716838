define('fitbase/user/adapter', ['exports', 'fitbase/application/adapter'], function (exports, _adapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend({
    urlTemplate: '{+host}/{+namespace}/users{/id}',
    createRecordUrlTemplate: '{+host}/{+namespace}/register'
  });
});