define('fitbase/components/focus-first-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.focusableSelectors = ['[type="text"]', '[type="email"]', '[type="password"]', 'textarea'];
    },
    didInsertElement: function didInsertElement() {
      var selectors = this.get('focusableSelectors').join(',');
      this.$(selectors).first().focus();
    }
  });
});