define('fitbase/components/client-li/component', ['exports', 'moment', 'fitbase/helpers/moment-ref-date'], function (exports, _moment, _momentRefDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var htmlSafe = Ember.String.htmlSafe;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: 'li',
    classNames: ['client-li'],
    classNameBindings: ['client.state', 'isLate', 'ended', 'uncoached'],
    uncoached: alias('client.hasUncoachedMessage'),

    ended: alias('client.isEnded'),

    isLate: computed('client.{state,state_updated_at}', function () {
      var isOpen = this.get('client.state') == 'open_checkin';
      var updatedAt = this.get('client.stateUpdatedAt');
      var isLateAt = (0, _moment.default)(updatedAt).add(1, 'days');
      return (0, _moment.default)().isAfter(isLateAt) && isOpen;
    }),

    checkinForm: alias('client.checkinForm'),

    statusMessage: computed('client.{state,state_updated_at,isEnded}', function () {
      var state = this.get('client.state');
      var updatedAt = this.get('client.stateUpdatedAt');
      var now = (0, _moment.default)();
      var updatedRelative = (0, _moment.default)(updatedAt).from(now);

      if (this.get('client.isEnded')) {
        var endedRelative = (0, _moment.default)(this.get('client.icsDtend')).from(now);
        return 'Ended ' + endedRelative;
      }

      switch (state) {
        case "open_checkin":
          return 'Delivered ' + updatedRelative;
        case "new":
          return "New client needs setup";
        case "checked_in":
          return 'Checked in ' + updatedRelative;
        case "replied":
          return 'Sent reply ' + updatedRelative;
        case "enqueued":
          return 'Scheduled';
        case "paused":
          return 'Paused';
      }
    }),

    nextAtMessage: computed('checkinForm', 'checkinForm.{isFulfilled,nextAt}', 'client.state', 'ended', function () {
      var clientState = get(this, 'client.state');
      var notFulfilled = !get(this, 'checkinForm.isFulfilled');
      if (clientState === "new" || notFulfilled) {
        return '';
      }
      var nextAt = get(this, 'checkinForm.nextAt');

      if (typeof nextAt == "undefined") {
        console.error('Undefined nextAt', get(this, 'checkinForm'));
      }

      if (nextAt && !this.ended) {
        var nextAtRelative = (0, _momentRefDate.relativeDate)(nextAt, true);
        return htmlSafe('Next: <b>' + nextAtRelative + '</b>');
      } else {
        return '';
      }
    })
  });
});