define('fitbase/coach/clients/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var filter = Ember.computed.filter;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    clients: alias('model'),
    activeClients: filter('model.@each.{state,isNew}', function (client) {
      return client.get('state') !== "archived" && client.get('isNew') == false;
    })
  });
});