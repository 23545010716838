define('fitbase/coach/clients/client/checkins/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend({
    model: function model() {
      var _this = this;

      var client = this.modelFor('coach.clients.client');

      return this.store.findRecord('client', client.get('id'), { reload: true }).then(function (client) {

        var fields = client.get('checkinForm').then(function (f) {
          if (!f) {
            return _this.transitionTo('coach.clients.client', client);
          }

          return f.reload().then(function () {
            return f.get('checkinFields');
          });
        });

        var checkins = client.hasMany('checkins').reload();

        return RSVP.hash({
          client: client,
          checkinFields: fields,
          checkins: checkins
        });
      });
    }
  });
});