define('fitbase/lib/field-schema-old', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A;
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  var empty = computed.empty,
      and = computed.and,
      or = computed.or,
      not = computed.not;


  var FieldSchema = EmberObject.extend({
    slug: null,
    label: null,
    unit: null,
    help: null,
    noLabel: empty('label'),
    noUnit: empty('unit'),
    image: null,
    isEmpty: and('noLabel', 'noUnit'),
    isInvalid: or('noLabel', 'noUnit'),
    isValid: not('isInvalid'),

    toString: function toString() {
      return this.get('isValid') ? JSON.stringify(this) : "";
    },


    inputType: computed('unit', function () {
      var unit = this.get('unit');
      for (var inputType in FieldSchema.unitInputMap) {
        var units = FieldSchema.unitInputMap[inputType];
        if (units.contains(unit)) {
          return inputType;
        }
      }
    })
  });

  FieldSchema.reopenClass({
    unitOptions: A(["inches", "centimeter", "pounds", "kilos", "minutes", "hours", "short text", "notes", "feelings", "date", "image"]),
    unitInputMap: {
      'number': ['inches', 'centimeters', 'pounds', 'kilos', 'minutes', 'hours'],
      'text': ['short text'],
      'textarea': ['notes'],
      'feeling': ['feelings'],
      'date': ['date'],
      'upload': ['image']
    }
  });

  exports.default = FieldSchema;
});