define('fitbase/components/flash-message-icon/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ["flash-message__icon"],
    type: "info",
    svgIcon: computed('type', function () {
      var prefix = "assets/images/";
      switch (this.type) {
        case 'newmessage':
          return prefix + 'icon-email';
        default:
          return prefix + 'icon-info-mono';
      }
    })
  });
});