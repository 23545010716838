define('fitbase/components/checkin-confirm/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var alias = Ember.computed.alias;
  var equal = Ember.computed.equal;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = Component.extend({
    session: service(),
    classNames: ['checkin-confirm'],

    subject: alias('session.subjectType'),
    isUser: equal('subject', 'user'),

    completedAndSaved: computed('subject', 'checkin.completedAndSaved', function () {
      if (get(this, 'subject') === "user") {
        return false;
      } else {
        return get(this, 'checkin.completedAndSaved');
      }
    }),

    actions: {
      goToField: function goToField(checkinField) {
        get(this, 'goToField')(checkinField);
      },
      submit: function submit() {
        get(this, 'submit')();
      }
    }
  });
});