define('fitbase/checkin-form/serializer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty;
  var get = Ember.get;
  exports.default = _emberData.default.JSONAPISerializer.extend({
    serialize: function serialize(snapshot) {
      var json = this._super.apply(this, arguments);
      var order = get(snapshot, 'fieldsOrder');
      if (isEmpty(order)) {
        return json;
      }

      json.attributes.order = order;
      return json;
    }
  });
});