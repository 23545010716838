define('fitbase/checkin-template/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    name: attr('string'),
    fields: attr(),
    user: belongsTo('user'),

    setFields: function setFields(fieldModelsArray) {
      var fieldsObj = {};

      fieldModelsArray.forEach(function (field, i) {
        var attrs = field.serialize()["data"]["attributes"];
        fieldsObj[i] = {
          text: attrs.text,
          unit: attrs.unit,
          sequence: attrs.sequence
        };
      });
      this.set('fields', fieldsObj);
    }
  });
});