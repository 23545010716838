define('fitbase/coach/clients/client/edit/checkin-form/import/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    selectedTemplate: null, // set by the route action
    fields: computed('selectedTemplate', function () {
      var fields = this.get('selectedTemplate.fields');
      return Object.getOwnPropertyNames(fields).map(function (key) {
        return fields[key];
      }).sort(function (_ref, _ref2) {
        var s1 = _ref.sequence;
        var s2 = _ref2.sequence;

        if (s1 == s2) {
          return 0;
        }
        return s1 < s2 ? -1 : 1;
      });
    })
  });
});